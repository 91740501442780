import { message } from "antd";
import { useSelector } from "react-redux";
import { searchActions, SearchInterface } from "redux/reducers/search.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { onSearch } from "services/search/search.service";

const useSearch = () => {
	const { responses, status, error, searchedBy } = useSelector(
		(state: RootState) => state.search
	);
	const dispatch = useAppDispatch();

	const searchEmptyRooms = async (values: object) => {
		try {
			dispatch(searchActions.loading(values as SearchInterface));
			const resps = await onSearch(values);
			dispatch(searchActions.populateResponse(resps));
		} catch (ex) {
			message.error({
				content: "Could not load data at the moment.",
			});
		}
	};

	return { responses, searchedBy, status, error, searchEmptyRooms };
};

export default useSearch;
