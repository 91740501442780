import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { uiActions } from "redux/reducers/ui.redux";

const useUI = () => {
	const { dimensions, sidebar } = useSelector((state: RootState) => state.ui);
	const dispatch = useAppDispatch();

	const toggleSidebarCollapse = () =>
		dispatch(uiActions.toggleSidebarCollapse());

	const setDeviceAsTabletOrMobile = (value: boolean) =>
		dispatch(uiActions.setDimensionTabletOrMobile(value));

	return {
		dimensions,
		sidebar,
		toggleSidebarCollapse,
		setDeviceAsTabletOrMobile,
	};
};

export default useUI;
