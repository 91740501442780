import React, { FC } from "react";
import { RoomModel } from "services/room/room.model";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";
import useEditModal from "common/hooks/useEditModal";
import RoomEditModal from "./RoomEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import useRooms from "../hooks/useRooms";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onRoomDelete } from "services/room/room.service";
import { roomActions } from "redux/reducers/rooms.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";

const tableModel: TableModelFunction<RoomModel> = (openEditor, onDelete) => [
	{
		name: "Room",
		selector: "roomNumber",
		cell: (room) => <span>{room.roomNumber}</span>,
		sortable: true,
		maxWidth: "70px",
	},
	{
		name: "Block",
		selector: "related_fields.block_id",
		cell: (room) => <span>{room.related_fields.block_id}</span>,
		sortable: true,
	},
	{
		name: "Capacity",
		selector: "roomCapacity",
		cell: (room) => <span>{room.roomCapacity}</span>,
		sortable: true,
		center: true,
		maxWidth: "70px",
	},
	{
		name: "Type",
		selector: "roomType",
		cell: (room) => <span>{room.roomType}</span>,
	},
	{
		name: "Actions",
		cell: (room) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							openEditor(room);
						}}
						onDeleteClick={() => {
							onDelete(room);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminRoomTableProps {
	rooms: RoomModel[];
	isLoading: boolean;
}

const AdminRoomTable: FC<AdminRoomTableProps> = ({ rooms, isLoading }) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<RoomModel>();

	const { rooms: rms } = useRooms();
	const { openModal, closeModal, visible, name, onFinish } = useDeleteModal(
		onRoomDelete,
		roomActions.deleteRoom
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(room) => {
							const _rm = rms.find((rm) => rm.id === room.id);
							openEditModal(_rm);
						},
						(room) => {
							openModal(room, `room: ${room.roomNumber}`);
						}
					) as any
				}
				data={rooms}
				isLoading={isLoading}
			/>
			<RoomEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ visible, closeModal, onFinish, name }} />
		</>
	);
};

export default AdminRoomTable;
