import React from "react";
import { Button } from "antd";
import styled from "styled-components";

import useUI from "common/hooks/useUI";

import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

import logo from "common/assets/ku-logo.png";
import DashboardSwitchButton from "./DashboardSwitchButton";

interface StyledMobileHeaderProps {
	isTabletOrMobile: boolean;
}

const StyledMobileHeader = styled.header<StyledMobileHeaderProps>`
	width: 100%;
	height: 80px;
	background-color: white;
	box-shadow: 0px 0px 20px rgba(60, 60, 60, 0.25);
	padding: 5px 0;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;

	display: ${(props) => (props.isTabletOrMobile ? "grid" : "none")};
	grid-template-columns: 5fr 70px 5fr;
	grid-template-areas: "action logo space";

	.logo {
		grid-area: logo;
		width: 70px;

		img {
			width: 70px;
		}
	}
	.action {
		grid-area: action;
		display: flex;
		align-items: center;
		padding-left: 20px;

		svg {
			transform: translateY(4px);
		}
	}
	.space {
		grid-area: space;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 20px;
	}
`;

function CPlaygroundMobileHeader() {
	const { dimensions, sidebar, toggleSidebarCollapse } = useUI();
	return (
		<StyledMobileHeader isTabletOrMobile={dimensions.isTabletOrMobile}>
			<div className="action">
				<Button
					type="link"
					onClick={() => {
						toggleSidebarCollapse();
					}}
				>
					{sidebar.collapsed ? (
						<CaretLeftOutlined style={{ fontSize: 18 }} />
					) : (
						<CaretRightOutlined style={{ fontSize: 18 }} />
					)}
				</Button>
			</div>
			<div className="logo">
				<img src={logo} alt="ku logo" />
			</div>
			<div className="space">
				<DashboardSwitchButton />
			</div>
		</StyledMobileHeader>
	);
}

export default CPlaygroundMobileHeader;
