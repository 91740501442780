import { message as msg } from "antd";
import { useSelector } from "react-redux";
import { departmentActions } from "redux/reducers/departments.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { onDepartmentGet } from "services/departments/department.service";

const useDepartments = () => {
	const { departments, error, status } = useSelector(
		(state: RootState) => state.departments
	);
	const dispatch = useAppDispatch();

	const fetchDepartments = async () => {
		try {
			dispatch(departmentActions.loading());
			const departsFromApi = await onDepartmentGet();
			dispatch(departmentActions.addDepartments(departsFromApi));
		} catch (ex) {
			const {
				message = "Could not fetch department data at the moment.",
			} = ex;
			dispatch(departmentActions.failed(message));
			msg.error(message);
		}
	};

	return { departments, error, status, fetchDepartments };
};
export default useDepartments;
