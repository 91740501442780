import React from "react";
import { Button } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router";

const GoBackButton = () => {
	const history = useHistory();
	return (
		<Button onClick={() => history.goBack()}>
			<AiOutlineArrowLeft />
		</Button>
	);
};

export default GoBackButton;
