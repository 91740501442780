import { Select } from "antd";
import LoadingIndicator from "common/components/LoadingIndicator";
import { filterByGroup } from "common/utils/routines";
import React, { useEffect, useState } from "react";
import { GroupModel } from "services/groups/groups.model";
import { TransitionedRoutePage } from "../admin.style";
import useGroups from "../Groups/hooks/useGroups";

import AdminPageHeader from "../widgets/PageHeader";
import useRoutines from "./hooks/useRoutines";
import RoutineCalendar from "./widgets/RoutineCalendar";

function RoutineAdmin() {
	const {
		routines,
		error,
		fetchRoutines,
		status: routineStatus,
	} = useRoutines();
	const [group, setGroup] = useState<GroupModel | null>(null);

	const { groups, findById, fetchGroups, status: groupStatus } = useGroups();

	useEffect(() => {
		if (!routines.length) fetchRoutines();
		if (!groups.length) fetchGroups();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!group) setGroup(groups[0]);
	}, [groups, group]);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Routines"
				indicator={
					<LoadingIndicator loading={[groupStatus, routineStatus]} />
				}
			>
				<Select
					style={{ width: 80 }}
					defaultValue={groups[0]?.id || 1}
					onChange={(val) => {
						const selectedGroup = findById(val);
						setGroup(selectedGroup || null);
					}}
				>
					{groups.map((item, i) => (
						<Select.Option key={item.groupName + i} value={item.id}>
							{item.groupName}
						</Select.Option>
					))}
				</Select>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<RoutineCalendar
				group={group?.id}
				routine={filterByGroup(group?.groupName || "", routines)}
			/>
		</TransitionedRoutePage>
	);
}

export default RoutineAdmin;
