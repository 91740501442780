import React, { FC, useState } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Form, Input, ModalProps, Select } from "antd";

import { UserLoginProps, UserModel } from "services/user/user.model";
import { onUserCreate } from "services/user/user.service";

import { userActions } from "redux/reducers/users.redux";

import { userRoles } from "./UserTable";
import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";

export interface UserCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const UserCreateModal: FC<UserCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		UserLoginProps,
		UserModel
	>({
		serviceFunction: onUserCreate,
		action: userActions.appendUsers,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const [role, setRole] = useState<number>(2);

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Create user"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values: any) => {
					values.role_id = role;

					createRecord(values as UserLoginProps);
				}}
				layout="vertical"
			>
				<Form.Item
					name="username"
					label="Username"
					rules={[
						{
							required: true,
							message: "Username is required.",
						},
					]}
				>
					<Input autoComplete="off" />
				</Form.Item>

				<Form.Item
					name="password"
					label="Password"
					rules={[
						{
							required: true,
							message: "Password is required.",
						},
					]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item name="role_id" label="Role">
					<Select
						allowClear
						onChange={(e: number) => {
							setRole(e);
						}}
						defaultValue={role}
					>
						{userRoles.map((item, i) => (
							<Select.Option key={i} value={item.id}>
								{item.value}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				{role === 4 ? <SelectBlock required /> : null}

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default UserCreateModal;
