import React, { useEffect, useState } from "react";

import useSubjects from "./hooks/useSubjects";

import AdminPageHeader from "../widgets/PageHeader";
import { Button } from "antd";
import { TransitionedRoutePage } from "../admin.style";
import AdminSubjectTable from "./widgets/SubjectTable";
import SubjectCreateModal from "./widgets/SubjectCreateModal";
import LoadingIndicator from "common/components/LoadingIndicator";

function SubjectAdmin() {
	const { subjects, error, status, fetchSubjects } = useSubjects();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	useEffect(() => {
		if (!subjects.length) fetchSubjects();

		// eslint-disable-next-line
	}, []);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Subjects"
				indicator={<LoadingIndicator loading={[status]} />}
			>
				<Button onClick={() => setShowCreateModal(!!!showCreateModal)}>
					Add New
				</Button>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<AdminSubjectTable
				isLoading={status === "loading"}
				subjects={subjects}
			/>
			<SubjectCreateModal
				visible={!!showCreateModal}
				closeModal={() => setShowCreateModal(false)}
			/>
		</TransitionedRoutePage>
	);
}

export default SubjectAdmin;
