import React from "react";
import useRoutines from "./hooks/useRoutines";
import RoutineCalendar from "./widgets/RoutineCalendar";

import { TransitionedRoutePage } from "features/Admin/admin.style";

function Routine() {
	const { error } = useRoutines();
	return (
		<TransitionedRoutePage>
			{error && <p className="error">{error}</p>}
			<RoutineCalendar />
		</TransitionedRoutePage>
	);
}

export default Routine;
