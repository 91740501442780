import React, { FC, useEffect, useState } from "react";
import { Calendar, momentLocalizer, CalendarProps } from "react-big-calendar";
import moment from "moment";
import { RoutineEventModel } from "services/routine/routine.model";
import { Button } from "antd";

import "react-big-calendar/lib/css/react-big-calendar.css";
import useRoutineView from "../hooks/useRoutineView";
import {
	RoutineEventDetail,
	eventDetailContext,
} from "../contexts/eventDetailContext";
import AdminPageHeader from "features/Admin/widgets/PageHeader";
import useRooms from "features/Admin/Room/hooks/useRooms";
import useBlocks from "features/Admin/Blocks/hooks/useBlocks";
import LoadingIndicator from "common/components/LoadingIndicator";
import RoomInfoWidget from "./RoomInfoWidget";
import { api_config } from "common/configs/api_config";
import { GroupModel } from "services/groups/groups.model";
import useGroups from "features/Admin/Groups/hooks/useGroups";
import RoutineCreateModal from "features/Admin/Routines/widgets/RoutineCreateModal";
import useCreateRoutine from "features/Admin/Routines/hooks/useCreateRoutine";
import { SlotInfo } from "redux/reducers/routine.create.redux";
import useAuth from "common/hooks/useAuth";
import RoutineDetailModal from "./RoutineDetailModal";

const localizer = momentLocalizer(moment); // or globalizeLocalizer

interface RoutineCalendarProps {}

const RoutineCalendar: FC<RoutineCalendarProps> = () => {
	const { changeView, changeDate, toView, group, room } = useRoutineView();

	const { rooms, status: roomStatus, fetchRooms } = useRooms();
	const { blocks, status: blockStatus, fetchBlocks } = useBlocks();
	const { groups, status: groupStatus, fetchGroups } = useGroups();

	const [activeGroup, setActiveGroup] = useState<GroupModel | null>(null);
	const { createRoutine, cancelCreateRoutine } = useCreateRoutine();
	const { auth } = useAuth();

	useEffect(() => {
		if (group && groups.length) {
			const groupData = groups.find((item) => item.groupName === group);
			if (groupData) setActiveGroup(groupData);
		}
	}, [group, groups]);

	useEffect(() => {
		if (!rooms.length) fetchRooms();
		if (!blocks.length) fetchBlocks();
		if (!groups.length) fetchGroups();
		// eslint-disable-next-line
	}, []);

	const [showEventDetail, setShowEventDetail] = useState<RoutineEventDetail>({
		showModal: false,
		slot: null,
	});

	const opts: CalendarProps = {
		step: 60,
		events: toView,
		selectable: true,
		timeslots: 1,
		endAccessor: "end",
		defaultView: "week",
		drilldownView: "day",
		startAccessor: "start",
		localizer,
		views: ["week", "day"],
		style: { height: "calc(100vh - 80px)", padding: "0px 0" },
		defaultDate: new Date(),
	};

	const getHeader = () => {
		if (group) return ` - ${group}`;
		else if (!room) return "";
		else {
			const rm = rooms.find((it) => it.id === room);
			if (!rm) return "";
			const block = blocks.find(
				(it) => it.id === rm.related_fields.block_id
			);
			if (!block) return ` - ${rm.roomNumber}`;
			return ` - ${block.blockName}, ${rm.roomNumber}`;
		}
	};

	return (
		<div>
			<AdminPageHeader
				page={`Routines${getHeader()}`}
				actionButton={
					room ? (
						<RoomInfoWidget header={getHeader()} room={room} />
					) : null
				}
				indicator={
					<LoadingIndicator
						loading={[roomStatus, blockStatus, groupStatus]}
					/>
				}
			>
				{activeGroup ? (
					<Button
						onClick={() => {
							if (room)
								window.open(
									api_config.API_BASEURL +
										"/routineSlot/by_room_excel/" +
										activeGroup.id,
									"_blank"
								);
							else
								window.open(
									api_config.API_BASEURL +
										"/routineSlot/by_group_excel/" +
										activeGroup.id,
									"_blank"
								);
						}}
					>
						Download CSV
					</Button>
				) : null}
			</AdminPageHeader>
			<Calendar
				{...opts}
				onNavigate={(newDate) => {
					changeDate(newDate);
				}}
				onView={changeView}
				onSelectEvent={(er) => {
					setShowEventDetail({
						showModal: true,
						slot: er as RoutineEventModel,
					});
				}}
				onSelectSlot={(ev) => {
					createRoutine(ev as SlotInfo, activeGroup?.id);
				}}
			/>
			<RoutineCreateModal
				title="Request reservation"
				closeModal={cancelCreateRoutine}
				userId={auth.id}
			/>
			<eventDetailContext.Provider value={{ ...showEventDetail }}>
				<RoutineDetailModal
					onCancel={() => {
						setShowEventDetail({
							showModal: false,
							slot: null,
						});
					}}
				/>
			</eventDetailContext.Provider>
		</div>
	);
};

export default RoutineCalendar;
