import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { DepartmentModel } from "services/departments/department.model";

interface rDepartment {
	departments: DepartmentModel[];
	error?: string;
	status: Status;
}

const initState: rDepartment = {
	departments: [],
	error: "",
	status: "idle",
};

// actions
const appendDepartments: CaseReducer<
	rDepartment,
	PayloadAction<DepartmentModel[]>
> = (state, action): rDepartment => ({
	status: "loaded",
	error: "",
	departments: [...state.departments, ...action.payload],
});

const updateDepartment: CaseReducer<
	rDepartment,
	PayloadAction<{ id: number; data: DepartmentModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		departments: state.departments.map((dept) => {
			if (dept.id !== id) return dept;
			return { ...data, id };
		}),
	};
};
const addDepartments: CaseReducer<
	rDepartment,
	PayloadAction<DepartmentModel[]>
> = (_, action): rDepartment => ({
	status: "loaded",
	error: "",
	departments: action.payload,
});

const deleteDepartment: CaseReducer<
	rDepartment,
	PayloadAction<{ id: number }>
> = (state, action) => ({
	...state,
	departments: state.departments.filter(
		(dept) => dept.id !== action.payload.id
	),
});
const resetDepartments: CaseReducer = (): rDepartment => initState;
const loading: CaseReducer<rDepartment> = (state): rDepartment => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rDepartment, PayloadAction<string>> = (
	state,
	action
): rDepartment => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const departmentSlice = createSlice({
	name: "departments",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addDepartments,
		appendDepartments,
		updateDepartment,
		resetDepartments,
		deleteDepartment,
	},
});

// exports
export const departmentActions = departmentSlice.actions;
export default departmentSlice;
