import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

export interface rRecordDelete {
	error?: string;
	status: Status;
	visible: boolean;
	record: any;
	name: string;
}
const initState: rRecordDelete = {
	error: "",
	status: "idle",
	record: {},
	visible: false,
	name: "",
};

// actions
const open: CaseReducer<
	rRecordDelete,
	PayloadAction<{ record: any; name: string }>
> = (state, action): rRecordDelete => ({
	...state,
	...action.payload,
	visible: true,
	status: "idle",
	error: "",
});
const close: CaseReducer = (): rRecordDelete => initState;
const loading: CaseReducer<rRecordDelete> = (state): rRecordDelete => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rRecordDelete, PayloadAction<string>> = (
	state,
	action
): rRecordDelete => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const recordDeleteSlice = createSlice({
	name: "record.delete",
	initialState: initState,
	reducers: { open, close, loading, failed },
});

// exports
export const recordDeleteActions = recordDeleteSlice.actions;
export default recordDeleteSlice;
