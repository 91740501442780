import { Form, Select } from "antd";
import React, { FC } from "react";

interface CWeekDaySelectProps {
	day: Date;
}

const weekDays = [
	{
		day: 0,
		name: "Sunday",
	},
	{
		day: 1,
		name: "Monday",
	},
	{
		day: 2,
		name: "Tuesday",
	},
	{
		day: 3,
		name: "Wednesday",
	},
	{
		day: 4,
		name: "Thursday",
	},
	{
		day: 5,
		name: "Friday",
	},
	{
		day: 6,
		name: "Saturday",
	},
];

const CWeekDaySelect: FC<CWeekDaySelectProps> = ({ day }) => (
	<Form.Item
		name="weekDay"
		label="Day of week"
		rules={[
			{
				required: true,
				message: "Day of week is required.",
			},
		]}
		initialValue={new Date(day).getDay()}
	>
		<Select>
			{weekDays.map((item, i) => (
				<Select.Option key={i} value={item.day}>
					{item.name}
				</Select.Option>
			))}
		</Select>
	</Form.Item>
);

export default CWeekDaySelect;
