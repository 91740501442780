import React, { FC } from "react";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";
import useEditModal from "common/hooks/useEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import { SubjectModel } from "services/subject/subject.model";
import SubjectEditModal from "./SubjectEditModal";
import useSubjects from "../hooks/useSubjects";
import DeleteModal from "features/Admin/widgets/DeleteModal";
import { onSubjectDelete } from "services/subject/subject.service";
import { subjectActions } from "redux/reducers/subject.redux";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";

const tableModel: TableModelFunction<SubjectModel> = (openEditor, onDelete) => [
	{
		name: "Name",
		selector: "subjectName",
		cell: (subject) => <span>{subject.subjectName}</span>,
		sortable: true,
	},
	{
		name: "Code",
		selector: "subjectCode",
		cell: (subject) => <span>{subject.subjectCode}</span>,
		sortable: true,
	},
	{
		name: "Actions",
		cell: (sub) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							openEditor(sub);
						}}
						onDeleteClick={() => {
							onDelete(sub);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminSubjectTableProps {
	subjects: SubjectModel[];
	isLoading: boolean;
}

const AdminSubjectTable: FC<AdminSubjectTableProps> = ({
	subjects,
	isLoading,
}) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<SubjectModel>();

	const { subjects: subs } = useSubjects();
	const { openModal, visible, name, onFinish, closeModal } = useDeleteModal(
		onSubjectDelete,
		subjectActions.deleteSubject
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(subject) => {
							const _sub = subs.find(
								(sub) => sub.id === subject.id
							);
							openEditModal(_sub);
						},
						(sub) => {
							openModal(
								sub,
								`Subject record: ${sub.subjectCode} - ${sub.subjectName}`
							);
						}
					) as any
				}
				data={subjects}
				isLoading={isLoading}
			/>
			<SubjectEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ visible, name, onFinish, closeModal }} />
		</>
	);
};

export default AdminSubjectTable;
