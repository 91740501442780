import React, { FC } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Checkbox, Form, InputNumber, ModalProps, Select } from "antd";

import { RoomModel, RoomCreateModel } from "services/room/room.model";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { roomActions } from "redux/reducers/rooms.redux";
import { onRoomsCreate } from "services/room/room.service";

import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";

export interface RoomCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const RoomCreateModal: FC<RoomCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		RoomCreateModel,
		RoomModel
	>({
		serviceFunction: onRoomsCreate,
		action: roomActions.appendRooms,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Create room"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					createRecord(values as RoomCreateModel);
				}}
				layout="vertical"
			>
				<SelectBlock />
				<Form.Item
					name="roomNumber"
					label="Room Number"
					rules={[
						{
							required: true,
							message: "Room number is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="roomCapacity"
					label="Capacity"
					rules={[
						{
							required: true,
							message: "Password is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="roomType"
					label="Room Type"
					rules={[
						{
							required: true,
							message: "Choose a room type.",
						},
					]}
				>
					<Select allowClear placeholder="Choose a type">
						{["classroom", "lab"].map((item, i) => (
							<Select.Option value={item} key={i}>
								{item}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name="projectorAvailable" valuePropName="checked">
					<Checkbox>Room has a projector</Checkbox>
				</Form.Item>

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default RoomCreateModal;
