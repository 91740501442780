import styled from "styled-components";
import { Form, Modal } from "antd";
import { FadeInTransition } from "common/styles/animations";

export const AdminWelcomeWrapper = styled.div`
	margin-top: 15px;
	height: calc(100vh - 50px);
	display: grid;
	place-items: center;

	border: 5px solid lightgray;
	text-align: center;

	.content {
		color: grey;
		font-size: 18px;
		font-family: var(--ff);
	}
	h2 {
		color: grey;
		margin: 0;
	}
`;

export const AdminPageOptionList = styled.ul`
	width: 100%;
	margin: 0;
	padding: 0;
	padding-top: 20px;

	display: flex;
	gap: 10px;
	flex-direction: column;

	li {
		width: 100%;
		border-bottom: 1px solid lightgrey;

		a {
			/* border-radius: 5px; */
			display: block;
			padding: 10px 15px;
			font-weight: 600;
			font-size: var(--fs);
			color: var(--black);
			/* border: 3px solid #f0f0f0; */
			background-color: rgba(0, 0, 0, 0);
			transition: 0.15s linear;
			border-right: 5px solid rgba(0, 0, 0, 0);

			&:hover {
				background-color: lightgrey;
			}

			&.active {
				background-color: #f1f1f1;
				border-right: 5px solid #5894ff;
				/* color: white; */
			}
		}
	}
`;

export const AdminPageHeaderWrapper = styled.div`
	width: 100%;
	padding: 10px 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	h2 {
		font-weight: 500;
		font-size: var(--fs-l);
	}
`;

export const StyledModal = styled(Modal)`
	overflow: hidden;
	border-radius: 10px;
	padding-bottom: 0;
`;

export const StyledForm = styled(Form)`
	span {
		box-shadow: none;
	}
	div {
		box-shadow: none;

		&[role="alert"] {
			font-size: var(--fs-sm);
		}
	}
	.ant-form-item-label {
		padding-bottom: 0;
		font-weight: 500;
	}
	.ant-select-focused {
		.ant-select-selector {
			box-shadow: none !important;
		}
	}
	.ant-select-selector {
		border: none !important;
		border-bottom: 2px solid lightgray !important;
		padding-left: 2.5px !important;
		padding-right: 2.5px !important;
	}
	.ant-input-number {
		width: 100%;
		border: none;
	}
	.ant-input-affix-wrapper {
		border: none;
		border-bottom: 2px solid lightgray;
	}
	.ant-picker-range {
		padding: 0;
	}
	.ant-form-item-control-input {
		align-items: flex-end;
	}
	label,
	input {
		font-family: var(--ff);
		font-size: var(--fs-sm);
	}
	input {
		border: none;
		border-bottom: 2px solid lightgray;

		font-weight: 500;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		&[type="password"] {
			letter-spacing: 2px;
		}
	}
`;

export const TransitionedRoutePage = styled.div`
	animation-duration: 0.25s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
	animation-name: ${FadeInTransition};
`;
