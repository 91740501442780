import { Menu } from "antd";
import { FC } from "react";
import styled from "styled-components";

const StyledMenuItem = styled(Menu.Item)`
	&.ant-menu-item {
		height: 30px;
		line-height: 30px;
		margin-bottom: 0;
	}
`;

interface TableActionDropdownProps {
	onEditClick: () => void;
	onDeleteClick?: () => void;
}

const TableActionDropdown: FC<TableActionDropdownProps> = ({
	onEditClick,
	onDeleteClick,
}) => {
	return (
		<Menu>
			<StyledMenuItem
				key="0"
				onClick={() => {
					onEditClick();
				}}
			>
				Edit record
			</StyledMenuItem>
			<StyledMenuItem
				key="1"
				onClick={() => {
					onDeleteClick && onDeleteClick();
				}}
			>
				Delete record
			</StyledMenuItem>
		</Menu>
	);
};

export default TableActionDropdown;
