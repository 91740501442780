import { HomeSidebarContentWrapper } from "features/Home/homewidget.style";
import React from "react";
import { NavLink } from "react-router-dom";

const SidebarContent: React.FC = () => {
	return (
		<HomeSidebarContentWrapper>
			<ul>
				<li>
					<NavLink to="/routines" exact>
						View Routines
					</NavLink>
				</li>
				<li>
					<NavLink to="/search">Find vacant rooms</NavLink>
				</li>
			</ul>
		</HomeSidebarContentWrapper>
	);
};

export default SidebarContent;
