import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filterByWeekDay, mapEvents } from "common/utils/routines";
import moment from "moment";
import {
	RoutineEventModel,
	RoutineModel,
} from "services/routine/routine.model";

interface rRoutineView {
	groupWiseRoutine: RoutineModel[];
	toView: RoutineEventModel[];
	date: Date;
	week: WeekRange;
	activeView: RoutineView;
	group: string | null;
	room: number | null;
	showBy: "room" | "group";
}

// utils
const dateToday = moment().toDate();
const getWeek = (dateNow: Date): WeekRange => [
	moment(dateNow).subtract(dateNow.getDay(), "days").toDate(),
	moment(dateNow).subtract(dateNow.getDay(), "days").add(6, "days").toDate(),
];
const mapWeekWiseEvents = (
	week: WeekRange,
	groupWiseRoutine: RoutineModel[]
) => {
	let toView: RoutineEventModel[] = [];
	for (
		let dt = week[0];
		moment(dt).isBetween(
			moment(week[0]).subtract(1, "day"),
			moment(week[1]).add(1, "day")
		);
		dt = moment(dt).add(1, "day").toDate()
	) {
		const dayWiseFiltered = filterByWeekDay(dt, groupWiseRoutine);
		const dayWise = mapEvents(dt, dayWiseFiltered);
		toView.push(...dayWise);
	}
	return toView;
};

// initial state
const initState: rRoutineView = {
	groupWiseRoutine: [],
	toView: [],
	date: dateToday,
	week: getWeek(dateToday),
	group: null,
	activeView: "week",
	showBy: "group",
	room: null,
};

// actions
const reset: CaseReducer = () => initState;

const changeShow: CaseReducer<rRoutineView, PayloadAction<"room" | "group">> = (
	state,
	action
) => ({
	...state,
	showBy: action.payload,
	groupWiseRoutine: [],
	toView: [],
	group: null,
	room: null,
});

const changeDate: CaseReducer<rRoutineView, PayloadAction<Date>> = (
	state,
	action
) => {
	const { groupWiseRoutine, week } = state;
	const date = action.payload;

	// date change in the same week
	if (
		moment(date).isBetween(
			moment(week[0]).subtract(1, "day"),
			moment(week[1]).add(1, "day")
		)
	)
		return { ...state, date };

	// date changes to new week
	const newWeek = getWeek(date);
	const toView: RoutineEventModel[] = mapWeekWiseEvents(
		newWeek,
		groupWiseRoutine
	);

	return { ...state, toView, date, week: newWeek };
};
const setRoomAndRoutines: CaseReducer<
	rRoutineView,
	PayloadAction<{ routines: RoutineModel[]; room: number }>
> = (state, action) => {
	const { week } = state;

	const groupWiseRoutine = action.payload.routines;

	const toView: RoutineEventModel[] = mapWeekWiseEvents(
		week,
		groupWiseRoutine
	);

	return {
		...state,
		room: action.payload.room,
		groupWiseRoutine,
		toView,
	};
};

const setGroupAndRoutines: CaseReducer<
	rRoutineView,
	PayloadAction<{ routines: RoutineModel[]; group: string }>
> = (state, action) => {
	const { week } = state;

	const groupWiseRoutine = action.payload.routines;

	const toView: RoutineEventModel[] = mapWeekWiseEvents(
		week,
		groupWiseRoutine
	);

	return {
		...state,
		group: action.payload.group,
		groupWiseRoutine,
		toView,
	};
};

const changeView: CaseReducer<rRoutineView, PayloadAction<RoutineView>> = (
	state,
	action
) => {
	const { week, groupWiseRoutine } = state;

	const toView: RoutineEventModel[] = mapWeekWiseEvents(
		week,
		groupWiseRoutine
	);

	return {
		...state,
		activeView: action.payload,
		toView,
	};
};

// slice
const routineViewSlice = createSlice({
	name: "routineView",
	initialState: initState,
	reducers: {
		reset,
		changeView,
		setGroupAndRoutines,
		changeDate,
		changeShow,
		setRoomAndRoutines,
	},
});

export const routineViewActions = routineViewSlice.actions;
export default routineViewSlice;
