import React, { FC } from "react";
import { Button, Form, Input, Modal, ModalProps } from "antd";
import { SubjectData, SubjectModel } from "services/subject/subject.model";
import { onSubjectUpdate } from "services/subject/subject.service";
import { subjectActions } from "redux/reducers/subject.redux";
import { StyledForm } from "features/Admin/admin.style";
import useEditRecord from "common/hooks/useEditRecord";
import SelectDepartment from "features/Admin/Departments/widgets/SelectDepartment";
import SelectGroup from "features/Admin/Groups/widgets/SelectGroup";
import SelectInstructor from "features/Admin/Instructors/widgets/SelectInstructor";

interface SubjectEditModalProps extends ModalProps {
	record: SubjectModel | null;
	closeModal: () => void;
}

const SubjectEditModal: FC<SubjectEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		SubjectData,
		SubjectModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onSubjectUpdate,
		action: subjectActions.updateSubject,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};
	return record ? (
		<Modal
			{...otherProps}
			title="Edit room"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as SubjectData);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item
					name="subjectName"
					label="Name"
					initialValue={record.subjectName}
				>
					<Input type="text" />
				</Form.Item>
				<Form.Item
					name="subjectCode"
					label="Code"
					initialValue={record.subjectName}
				>
					<Input type="text" />
				</Form.Item>

				<SelectGroup />
				<SelectDepartment />
				<SelectInstructor />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default SubjectEditModal;
