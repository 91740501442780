import React, { FC } from "react";
import styled, { css } from "styled-components";

import useUI from "common/hooks/useUI";

import Sider from "antd/lib/layout/Sider";
import CSidebarHeader from "common/widgets/CSidebarHeader";
import CSidebarFooter from "common/widgets/CSidebarFooter";
import { useSwipeable } from "react-swipeable";

interface SidebarWrapperProps {
	isTabletOrMobile: boolean;
	collapsed: boolean;
}

const BigScreenStyles = css`
	flex: 0 0 30% !important;
	max-width: 350px !important;
	overflow: hidden;
`;

const SmallScreenStyles = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 350px !important;
	flex: 1 1 100% !important;
	max-width: 350px !important;
	height: 100vh;
	overflow: hidden;
	z-index: 99;
	transform-origin: 0 50%;
	transition: 0.15s ease-out;
`;

const SmallScreenCollapsedStyles = css`
	transform: translateX(-350px);
`;

const SidebarWrapper = styled(Sider)<SidebarWrapperProps>`
	${(props) => (props.isTabletOrMobile ? SmallScreenStyles : BigScreenStyles)}
	${(props) =>
		props.isTabletOrMobile && !props.collapsed
			? SmallScreenCollapsedStyles
			: ""}
	padding-top: ${(props) => (props.isTabletOrMobile ? "20px" : "0px")};

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}
	.content {
		width: 100%;
		height: 100%;
		overflow: hidden auto;

		padding: 5px 10px;
	}
`;

interface CSidebarProps {}

const CSidebar: FC<CSidebarProps> = ({ children }) => {
	const {
		sidebar: { collapsed },
		dimensions: { isTabletOrMobile },
		toggleSidebarCollapse,
	} = useUI();

	const handler = useSwipeable({
		onSwipedLeft: () => toggleSidebarCollapse(),
		delta: 50,
	});

	return (
		<SidebarWrapper
			theme="light"
			{...handler}
			{...{ isTabletOrMobile, collapsed }}
		>
			<CSidebarHeader />
			<div className="content">{children}</div>
			<CSidebarFooter />
		</SidebarWrapper>
	);
};

export default CSidebar;
