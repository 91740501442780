import { message as msg } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { blockActions } from "redux/reducers/blocks.redux";
import { RootState } from "redux/store.redux";
import { onBlocksGet } from "services/block/block.service";

const useBlocks = () => {
	const { blocks, error, status } = useSelector(
		(state: RootState) => state.blocks
	);
	const dispatch = useDispatch();

	const fetchBlocks = async () => {
		try {
			dispatch(blockActions.loading());
			const blocksFromApi = await onBlocksGet();
			dispatch(blockActions.addBlocks(blocksFromApi));
		} catch (ex) {
			const { message = "Could not fetch block data at the moment." } =
				ex;
			dispatch(blockActions.failed(message));
			msg.error(message);
		}
	};

	return { blocks, error, status, fetchBlocks };
};
export default useBlocks;
