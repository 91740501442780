import remoteApi from "common/remote/api";
import { InstructorData, InstructorModel } from "./instructor.model";

export const onInstructorGet = (): Promise<InstructorModel[]> =>
	remoteApi.get("/instructor");

export const onInstructorCreate = (
	payload: InstructorData
): Promise<InstructorModel> => remoteApi.post("/instructor", { ...payload });

export const onInstructorUpdate = (
	id: number,
	payload: InstructorData
): Promise<InstructorModel> => remoteApi.patch(`/instructor/${id}`, payload);

export const onInstructorDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/instructor/${id}`);
