import React, { useEffect } from "react";

import { plugConfigs } from "common/configs/antd_configs";
import GlobalStyles from "common/styles/global";
import { message } from "antd";

import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";

import { Provider } from "react-redux";
import store from "redux/store.redux";

import { authActions } from "redux/reducers/auth.redux";
import { uiActions } from "redux/reducers/ui.redux";

import useResponsiveness from "common/hooks/useResponsiveness";
import TokenUtils from "common/utils/token";

function App() {
	useEffect(() => {
		const populateRedux = async () => {
			try {
				const accessToken = TokenUtils.get();
				if (accessToken) {
					const decoded = TokenUtils.decode(accessToken);
					store.dispatch(
						authActions.login({
							id: decoded.id,
							username: decoded.username,
							role: decoded.role,
							accessToken,
						})
					);
				}
			} catch (ex) {
				TokenUtils.delete();
				message.error("Invalid access token. You've been logged out.");
			}
		};

		// plug UI configs for ant-design
		plugConfigs();
		// populate redux store
		populateRedux();
	}, []);

	// populate store with device dimension
	const { isTabletOrMobile } = useResponsiveness();
	useEffect(() => {
		store.dispatch(uiActions.setDimensionTabletOrMobile(isTabletOrMobile));
	}, [isTabletOrMobile]);

	return (
		<div className="App">
			<BrowserRouter>
				<GlobalStyles />
				<Provider store={store}>
					<AppRoutes />
				</Provider>
			</BrowserRouter>
		</div>
	);
}

export default App;
