import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { RoomModel } from "services/room/room.model";

interface rRooms {
	rooms: RoomModel[];
	error?: string;
	status: Status;
}

const initState: rRooms = {
	rooms: [],
	error: "",
	status: "idle",
};

// actions
const appendRooms: CaseReducer<rRooms, PayloadAction<RoomModel[]>> = (
	state,
	action
): rRooms => ({
	status: "loaded",
	error: "",
	rooms: [...state.rooms, ...action.payload],
});

const updateRoom: CaseReducer<
	rRooms,
	PayloadAction<{ id: number; data: RoomModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		rooms: state.rooms.map((room) => {
			if (room.id !== id) return room;
			return { ...data, id };
		}),
	};
};
const addRooms: CaseReducer<rRooms, PayloadAction<RoomModel[]>> = (
	_,
	action
): rRooms => ({
	status: "loaded",
	error: "",
	rooms: action.payload,
});
const deleteRoom: CaseReducer<rRooms, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	rooms: state.rooms.filter((room) => room.id !== action.payload.id),
});
const resetRooms: CaseReducer = (): rRooms => initState;
const loading: CaseReducer<rRooms> = (state): rRooms => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rRooms, PayloadAction<string>> = (
	state,
	action
): rRooms => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const roomSlice = createSlice({
	name: "room",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addRooms,
		appendRooms,
		updateRoom,
		resetRooms,
		deleteRoom,
	},
});

// exports
export const roomActions = roomSlice.actions;
export default roomSlice;
