import { createContext } from "react";
import { RoutineEventModel } from "services/routine/routine.model";

export interface RoutineEventDetail {
	showModal: boolean;
	slot: RoutineEventModel | null;
}

export const eventDetailContext = createContext<RoutineEventDetail>({
	slot: null,
	showModal: false,
});
