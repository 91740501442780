import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchEmptyRoomResponse } from "services/search/search.model";

export interface SearchInterface {
	blockId: string;
	roomType: string;
	hour: number;
	weekDay: number;
	roomCapacity: number;
	projectorAvailable: boolean;
}

interface rSearch {
	searchedBy: SearchInterface;
	responses: SearchEmptyRoomResponse;
	error?: string;
	status: Status;
}

const initSearchInterface: SearchInterface = {
	blockId: "",
	roomType: "",
	hour: 900,
	weekDay: new Date().getDay(),
	roomCapacity: 0,
	projectorAvailable: false,
};

const initState: rSearch = {
	searchedBy: initSearchInterface,
	responses: [],
	error: "",
	status: "idle",
};

const populateResponse: CaseReducer<
	rSearch,
	PayloadAction<SearchEmptyRoomResponse>
> = (state, action) => ({
	...state,
	error: "",
	status: "loaded",
	responses: action.payload,
});
const loading: CaseReducer<rSearch, PayloadAction<SearchInterface>> = (
	state,
	action
) => ({
	...state,
	searchedBy: action.payload,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rSearch, PayloadAction<string>> = (
	state,
	action
) => ({
	...state,
	status: "hasError",
	error: action.payload,
});
const reset = () => initState;

const searchSlice = createSlice({
	name: "search",
	initialState: initState,
	reducers: {
		loading,
		failed,
		reset,
		populateResponse,
	},
});

export const searchActions = searchSlice.actions;
export default searchSlice;
