import { RootState, useAppDispatch } from "redux/store.redux";
import { useSelector } from "react-redux";
import { RoutineModel } from "services/routine/routine.model";
import { routineViewActions } from "redux/reducers/routine.view.redux";

const useRoutineView = () => {
	const routineViewProperties = useSelector(
		(state: RootState) => state.routineView
	);

	const dispatch = useAppDispatch();

	const changeGroup = (group: string, routines: RoutineModel[]) => {
		dispatch(routineViewActions.setGroupAndRoutines({ routines, group }));
	};
	const changeView = (view: string) => {
		dispatch(routineViewActions.changeView(view as RoutineView));
	};
	const changeDate = (date: Date) => {
		dispatch(routineViewActions.changeDate(date));
	};
	const reset = (permission: number) => {
		if (permission === 0) dispatch(routineViewActions.reset());
	};

	// all return
	return {
		...routineViewProperties,
		changeDate,
		changeGroup,
		changeView,
		reset,
	};
};

export default useRoutineView;
