import React, { FC, useEffect } from "react";
import { Form, Select } from "antd";

import useBlocks from "../hooks/useBlocks";
import { Rule } from "antd/lib/form";

interface SelectBlockProps {
	margin?: string;
	name?: string;
	required?: boolean;
	placeholder?: string;
	rules?: Rule[];
}

const SelectBlock: FC<SelectBlockProps> = ({
	margin,
	name,
	required,
	placeholder = "Choose a block",
}) => {
	const { blocks, status, fetchBlocks } = useBlocks();
	useEffect(() => {
		if (!blocks || !blocks.length) fetchBlocks();
		// eslint-disable-next-line
	}, []);

	const marginStyle = margin ? { margin } : {};

	return (
		<Form.Item
			name={name || "block_id"}
			label="Block"
			rules={[
				{
					required,
					message: "Choose a block",
				},
			]}
			style={marginStyle}
		>
			<Select
				showSearch
				loading={status === "loading"}
				allowClear
				placeholder={placeholder}
				filterOption={(input, option) =>
					option?.children
						?.toLowerCase()
						?.indexOf(input.toLowerCase()) >= 0
				}
			>
				{blocks.map((item, i) => (
					<Select.Option
						value={item.id}
						label={item.blockName}
						key={i}
					>
						{item.blockName}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default SelectBlock;
