import { Form, Spin } from "antd";
import useAuth from "common/hooks/useAuth";
import React from "react";
import {
	LoginButton,
	LoginErrorMessage,
	LoginFormContainer,
	LoginFormHeader,
	LoginFormHeaderWrapper,
	LoginFormSubHeader,
	LoginFormWrapper,
	LoginInput,
} from "../loginwidget.style";

const LoginFormWidget: React.FC = () => {
	const { logUserIn, auth } = useAuth();

	return (
		<LoginFormWrapper>
			<LoginFormHeaderWrapper>
				<LoginFormHeader>Routine Management System</LoginFormHeader>
				<LoginFormSubHeader>
					Track status of classrooms in different blocks.
				</LoginFormSubHeader>
			</LoginFormHeaderWrapper>
			<LoginFormContainer>
				<Form
					name="normal_login"
					className="login-form"
					initialValues={{ username: "", password: "" }}
					onFinish={logUserIn}
				>
					<Form.Item
						name="username"
						rules={[
							{
								required: true,
								message: "Username is required!",
							},
						]}
					>
						<LoginInput placeholder="Username" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your Password!",
								whitespace: false,
							},
						]}
					>
						<LoginInput type="password" placeholder="Password" />
					</Form.Item>

					<LoginErrorMessage>{auth.error}</LoginErrorMessage>

					<Form.Item>
						<Spin spinning={auth.status === "loading"}>
							<LoginButton
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Log in
							</LoginButton>
						</Spin>
					</Form.Item>
				</Form>
			</LoginFormContainer>
		</LoginFormWrapper>
	);
};

export default LoginFormWidget;
