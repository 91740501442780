import React, { FC } from "react";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";
import useEditModal from "common/hooks/useEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import { GroupModel } from "services/groups/groups.model";
import GroupEditModal from "./GroupEditModal";
import useGroups from "../hooks/useGroups";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onGroupDelete } from "services/groups/groups.service";
import { groupActions } from "redux/reducers/group.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";

const tableModel: TableModelFunction<GroupModel> = (onEdit, onDelete) => [
	{
		name: "Name",
		selector: "groupName",
		cell: (subject) => <span>{subject.groupName}</span>,
		sortable: true,
	},
	{
		name: "Year",
		selector: "groupYear",
		cell: (subject) => <span>{subject.year}</span>,
		sortable: true,
	},
	{
		name: "Students",
		selector: "numberOfStudents",
		cell: (subject) => <span>{subject.numberOfStudents}</span>,
		sortable: true,
	},
	{
		name: "Actions",
		cell: (group) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							onEdit(group);
						}}
						onDeleteClick={() => {
							onDelete(group);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminGroupTableProps {
	groups: GroupModel[];
	isLoading: boolean;
}

const AdminGroupTable: FC<AdminGroupTableProps> = ({ groups, isLoading }) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<GroupModel>();

	const { groups: grps } = useGroups();
	const { visible, name, onFinish, closeModal, openModal } = useDeleteModal(
		onGroupDelete,
		groupActions.deleteGroup
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(group) => {
							const _grp = grps.find(
								(grp) => grp.id === group.id
							);
							openEditModal(_grp);
						},
						(group) => {
							openModal(group, `group: ${group.groupName}`);
						}
					) as any
				}
				data={groups}
				isLoading={isLoading}
			/>
			<GroupEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ visible, onFinish, closeModal, name }} />
		</>
	);
};

export default AdminGroupTable;
