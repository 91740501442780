import styled from "styled-components";

export const RoomListItem = styled.li`
	padding: 7.5px;
	cursor: pointer;

	font-weight: normal;
	color: black;

	background: rgba(190, 190, 190, 0);
	transition: 0.15s ease;

	&.active {
		font-weight: 600;
		background: rgba(190, 190, 190, 0.4);
	}
	&:hover {
		background: rgba(190, 190, 190, 0.4);
	}
`;
