import React, { useState } from "react";
import useRoutines from "../hooks/useRoutines";

import { weekdays } from "moment";
import { parseTime } from "common/utils/time";
import { StyledModal } from "features/Admin/admin.style";

import { eventDetailContext } from "../contexts/eventDetailContext";
import { useContext } from "react";
import { RoutineModel } from "services/routine/routine.model";
import { useEffect } from "react";
import { Tag } from "antd";
import useBlocks from "features/Admin/Blocks/hooks/useBlocks";
import useRooms from "features/Admin/Room/hooks/useRooms";

function RoutineDetailModal({ onCancel }: { onCancel: () => void }) {
	const { slot, showModal } = useContext(eventDetailContext);

	const { routines } = useRoutines();

	const [rt, setRt] = useState<RoutineModel | null>(null);
	const [roomDetails, setRoomDetails] = useState<{
		room: string;
		block: string;
	} | null>(null);

	const { rooms } = useRooms();
	const { blocks } = useBlocks();

	useEffect(() => {
		const _rt = slot && routines.find((item) => item.id === slot.id);
		if (_rt) {
			const room = rooms.find((item) => item.id);
			if (room) {
				const block = blocks.find(
					(item) => item.id === room.related_fields.block_id
				);
				setRoomDetails({
					room: `${room.roomNumber}`,
					block: block?.blockName || "Unspecified",
				});
			} else setRoomDetails(null);

			setRt(_rt);
		}
	}, [slot, routines, rooms, blocks]);

	return (
		<StyledModal
			title="Routine Info"
			visible={showModal}
			onCancel={onCancel}
			footer={null}
		>
			<div className="tags">
				{rt?.temporaryOn ? <Tag color="yellow">Temporary</Tag> : null}
				{rt && !rt.canceledOn && !rt.temporaryOn ? (
					<Tag color="green">Regular</Tag>
				) : null}
			</div>
			<h2>{rt?.info.subject || "Unspecified"}</h2>
			<p>
				<b>Who: </b>
				{rt?.info.instructor || "Unspecified"} -{" "}
				{rt?.info.group || "Unspecified"}
			</p>

			<p>
				<b>When: </b>
				{parseTime(rt?.startTime || 800)} -{" "}
				{parseTime(rt?.endTime || 900)}, {weekdays(rt?.weekDay || 0)}
			</p>

			<p>
				<b>Where: </b>
				{roomDetails
					? `${roomDetails.block}, Room ${roomDetails.room}`
					: "Unspecified"}
			</p>
		</StyledModal>
	);
}

export default RoutineDetailModal;
