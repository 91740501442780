import React from "react";

import { LoginViewWrapper } from "./loginview.style";

import LoginFormWidget from "features/Login/widgets/LoginFormWidget";
import LoginSchoolLogoWidget from "features/Login/widgets/LoginSchoolLogo";

const LoginView: React.FC = () => {
	return (
		<LoginViewWrapper>
			<LoginSchoolLogoWidget />
			<LoginFormWidget />
		</LoginViewWrapper>
	);
};

export default LoginView;
