import React, { FC } from "react";
import { UserModel } from "services/user/user.model";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";

import useEditModal from "common/hooks/useEditModal";
import UserEditModal from "./UserEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import useUsers from "../hooks/useUsers";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onUserDelete } from "services/user/user.service";
import { userActions } from "redux/reducers/users.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";
import { message } from "antd";

export const userRoles = [
	{ value: "admin", id: 1 },
	{ value: "client", id: 2 },
	{ value: "superadmin", id: 3 },
	{ value: "blockadmin", id: 4 },
];

const tableModel: TableModelFunction<UserModel> = (openEditor, onDelete) => [
	{
		name: "Name",
		selector: "username",
		cell: (user) => <span>{user.username}</span>,
		sortable: true,
	},
	{
		name: "Role",
		selector: "related_fields.role_id",
		cell: (user) => (
			<span>
				{userRoles.find(
					(item) => item.id === user.related_fields.role_id
				)?.value || "user"}
			</span>
		),
		sortable: true,
	},
	{
		name: "Actions",
		cell: (user) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							openEditor(user);
						}}
						onDeleteClick={() => onDelete(user)}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminRoomTableProps {
	users: UserModel[];
	isLoading: boolean;
}

const AdminUserTable: FC<AdminRoomTableProps> = ({ users, isLoading }) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<UserModel>();

	const { users: usrs } = useUsers();
	const { openModal, visible, name, onFinish, closeModal } = useDeleteModal(
		onUserDelete,
		userActions.deleteUser
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(user) => {
							const _usr = usrs.find((usr) => usr.id === user.id);
							openEditModal(_usr);
						},
						(user) => {
							if (user.related_fields.role_id === 1) {
								message.error(
									"Only superadmin can delete an admin user."
								);
								return;
							}
							openModal(user, `User record of ${user.username}`);
						}
					) as any
				}
				data={users}
				isLoading={isLoading}
			/>
			<UserEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ visible, name, onFinish, closeModal }} />
		</>
	);
};

export default AdminUserTable;
