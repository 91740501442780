import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "services/user/user.model";

interface rUser {
	users: UserModel[];
	error?: string;
	status: Status;
}

const initState: rUser = {
	users: [],
	error: "",
	status: "idle",
};

// actions
const appendUsers: CaseReducer<rUser, PayloadAction<UserModel[]>> = (
	state,
	action
): rUser => ({
	status: "loaded",
	error: "",
	users: [...state.users, ...action.payload],
});

const updateUser: CaseReducer<
	rUser,
	PayloadAction<{ id: number; data: UserModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		users: state.users.map((user) => {
			if (user.id !== id) return user;
			return { ...data, id };
		}),
	};
};
const addUsers: CaseReducer<rUser, PayloadAction<UserModel[]>> = (
	_,
	action
): rUser => ({
	status: "loaded",
	error: "",
	users: action.payload,
});
const deleteUser: CaseReducer<rUser, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	users: state.users.filter((user) => user.id !== action.payload.id),
});
const resetUsers: CaseReducer = (): rUser => initState;
const loading: CaseReducer<rUser> = (state): rUser => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rUser, PayloadAction<string>> = (
	state,
	action
): rUser => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const userSlice = createSlice({
	name: "user",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addUsers,
		appendUsers,
		updateUser,
		resetUsers,
		deleteUser,
	},
});

// exports
export const userActions = userSlice.actions;
export default userSlice;
