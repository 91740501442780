import styled from "styled-components";

export const LoginViewWrapper = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;

	justify-content: flex-end;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`;
