import React, { FC } from "react";
import { Button, Form, Input, InputNumber, Modal, ModalProps } from "antd";
import { BlockData, BlockModel } from "services/block/block.model";
import { StyledForm } from "features/Admin/admin.style";
import useEditRecord from "common/hooks/useEditRecord";
import { blockActions } from "redux/reducers/blocks.redux";
import { onBlockUpdate } from "services/block/block.service";

interface BlockEditModalProps extends ModalProps {
	record: BlockModel | null;
	closeModal: () => void;
}

const BlockEditModal: FC<BlockEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		BlockData,
		BlockModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onBlockUpdate,
		action: blockActions.updateBlock,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit Block"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as BlockData);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item
					name="blockNumber"
					label="Block Number"
					initialValue={record.blockNumber}
					rules={[
						{
							required: true,
							message: "Block number is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="blockName"
					label="BlockName"
					initialValue={record.blockName}
					rules={[
						{
							required: true,
							message: "Name of block is required.",
						},
					]}
				>
					<Input />
				</Form.Item>
				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default BlockEditModal;
