import React, { FC } from "react";
import { Button, Form, Input, Modal, ModalProps } from "antd";
import {
	InstructorData,
	InstructorModel,
} from "services/instructor/instructor.model";
import { onInstructorUpdate } from "services/instructor/instructor.service";
import { instructorActions } from "redux/reducers/instructor.redux";
import useEditRecord from "common/hooks/useEditRecord";
import { StyledForm } from "features/Admin/admin.style";
import SelectDepartment from "features/Admin/Departments/widgets/SelectDepartment";

interface InstructorEditModalProps extends ModalProps {
	record: InstructorModel | null;

	closeModal: () => void;
}

const InstructorEditModal: FC<InstructorEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		InstructorData,
		InstructorModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onInstructorUpdate,
		action: instructorActions.updateInstructor,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit instructor"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as InstructorData);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item
					name="instructorName"
					label="Name"
					initialValue={record.instructorName}
				>
					<Input type="text" />
				</Form.Item>

				<SelectDepartment />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default InstructorEditModal;
