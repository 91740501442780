import React, { FC, useEffect } from "react";
import { Form, Select } from "antd";
import useSubjects from "../hooks/useSubjects";

const SelectSubject: FC<{}> = () => {
	const { subjects, status, fetchSubjects } = useSubjects();
	useEffect(() => {
		if (!subjects || !subjects.length) fetchSubjects();
		// eslint-disable-next-line
	}, []);

	return (
		<Form.Item
			name="subject_id"
			label="Subject"
			rules={[
				{
					required: true,
					message: "Choose a subject",
				},
			]}
		>
			<Select
				showSearch
				loading={status === "loading"}
				allowClear
				placeholder="Choose a subject"
				filterOption={(input, option) => {
					return (
						option?.children
							?.toLowerCase()
							?.indexOf(input.toLowerCase()) >= 0
					);
				}}
			>
				{subjects.map((item, i) => (
					<Select.Option
						value={item.id}
						label={item.subjectName}
						key={i}
						children={`${item.subjectName} - ${item.subjectCode}`}
					/>
				))}
			</Select>
		</Form.Item>
	);
};

export default SelectSubject;
