import React from "react";

import { HomeViewWrapper } from "./homeview.style";

import Playground from "features/Home/widgets/Playground.widget";
import Sidebar from "features/Home/widgets/Sidebar.widget";

function HomeView() {
	return (
		<HomeViewWrapper>
			<Sidebar />
			<Playground />
		</HomeViewWrapper>
	);
}

export default HomeView;
