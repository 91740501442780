import { message as msg } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { roomActions } from "redux/reducers/rooms.redux";
import { RootState } from "redux/store.redux";
import { onRoomsGet } from "services/room/room.service";

const useRooms = () => {
	const { rooms, error, status } = useSelector(
		(state: RootState) => state.rooms
	);
	const dispatch = useDispatch();

	const fetchRooms = async () => {
		try {
			dispatch(roomActions.loading());
			const roomsFromApi = await onRoomsGet();
			dispatch(roomActions.addRooms(roomsFromApi));
		} catch (ex) {
			const { message = "Could not fetch room data at the moment." } = ex;
			dispatch(roomActions.failed(message));
			msg.error(message);
		}
	};

	return { rooms, error, status, fetchRooms };
};
export default useRooms;
