import { Button, Modal, ModalProps, notification } from "antd";
import React, { FC } from "react";

const DeleteFooterButtons: FC<{
	onDelete: () => void | Promise<void>;
	onCancel: () => void;
}> = ({ onDelete, onCancel }) => (
	<>
		<Button onClick={onCancel} type="text">
			Cancel
		</Button>
		<Button onClick={onDelete} type="primary" danger>
			Delete
		</Button>
	</>
);

interface DeleteModalProps extends ModalProps {
	onFinish: () => Promise<void>;
	closeModal: () => void;
	visible: boolean;
	name: string;
}

const DeleteModal: FC<DeleteModalProps> = ({
	title = "Delete record?",
	visible,
	name,
	onFinish,
	closeModal,
	...otherProps
}) => {
	const onOk = async () => {
		try {
			console.log("Deleting...");
			if (onFinish) await onFinish();
		} catch (ex) {
			notification.error({
				message: "Deletion failed!!!",
			});
		}
	};

	return (
		<Modal
			title={title}
			{...otherProps}
			centered
			visible={visible}
			footer={
				<DeleteFooterButtons onDelete={onOk} onCancel={closeModal} />
			}
		>
			<h2>Are you sure?</h2>
			<p>You are about to delete {name}.</p>
			<small style={{ color: "red" }}>This action is irreversible.</small>
		</Modal>
	);
};

export default DeleteModal;
