import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { InstructorModel } from "services/instructor/instructor.model";

interface rInstructor {
	instructors: InstructorModel[];
	error?: string;
	status: Status;
}

const initState: rInstructor = {
	instructors: [],
	error: "",
	status: "idle",
};

// actions
const appendInstructors: CaseReducer<
	rInstructor,
	PayloadAction<InstructorModel[]>
> = (state, action): rInstructor => ({
	status: "loaded",
	error: "",
	instructors: [...state.instructors, ...action.payload],
});

const updateInstructor: CaseReducer<
	rInstructor,
	PayloadAction<{ id: number; data: InstructorModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		instructors: state.instructors.map((instructor) => {
			if (instructor.id !== id) return instructor;
			return { ...data, id };
		}),
	};
};
const addInstructors: CaseReducer<
	rInstructor,
	PayloadAction<InstructorModel[]>
> = (_, action): rInstructor => ({
	status: "loaded",
	error: "",
	instructors: action.payload,
});
const deleteInstructor: CaseReducer<
	rInstructor,
	PayloadAction<{ id: number }>
> = (state, action) => ({
	...state,
	instructors: state.instructors.filter(
		(ins) => ins.id !== action.payload.id
	),
});
const resetInstructors: CaseReducer = (): rInstructor => initState;
const loading: CaseReducer<rInstructor> = (state): rInstructor => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rInstructor, PayloadAction<string>> = (
	state,
	action
): rInstructor => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const instructorSlice = createSlice({
	name: "instructor",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addInstructors,
		appendInstructors,
		updateInstructor,
		resetInstructors,
		deleteInstructor,
	},
});

// exports
export const instructorActions = instructorSlice.actions;
export default instructorSlice;
