import { message, notification } from "antd";
import { ConfigOptions } from "antd/lib/message";
import { ConfigProps } from "antd/lib/notification";

const rtl = false;
const duration = 3; //seconds

const message_config: ConfigOptions = {
	maxCount: 3,
	duration,
	rtl,
};
const notification_config: ConfigProps = {
	placement: "bottomRight",
	duration,
	rtl,
};

export const plugConfigs = () => {
	message.config(message_config);
	notification.config(notification_config);
};
