import React, { useEffect, useState } from "react";

import useInstructor from "./hooks/useInstructor";

import AdminPageHeader from "../widgets/PageHeader";
import { Button } from "antd";
import { TransitionedRoutePage } from "../admin.style";
import InstructorCreateModal from "./widgets/InstructorCreateModal";
import AdminInstructorTable from "./widgets/InstructorTable";
import LoadingIndicator from "common/components/LoadingIndicator";

function InstructorAdmin() {
	const { instructors, error, status, fetchInstructors } = useInstructor();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	useEffect(() => {
		if (!instructors.length) fetchInstructors();

		// eslint-disable-next-line
	}, []);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Instructors"
				indicator={<LoadingIndicator loading={[status]} />}
			>
				<Button onClick={() => setShowCreateModal(!!!showCreateModal)}>
					Add New
				</Button>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<AdminInstructorTable
				instructors={instructors}
				isLoading={status === "loading"}
			/>
			<InstructorCreateModal
				visible={!!showCreateModal}
				closeModal={() => setShowCreateModal(false)}
			/>
		</TransitionedRoutePage>
	);
}

export default InstructorAdmin;
