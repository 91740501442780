import React, { useEffect, useState } from "react";

import useDepartments from "./hooks/useDepartments";

import AdminPageHeader from "../widgets/PageHeader";
import { Button } from "antd";
import { TransitionedRoutePage } from "../admin.style";
import AdminDepartmentTable from "./widgets/DepartmentTable";
import DepartmentCreateModal from "./widgets/DepartmentCreateModal";
import LoadingIndicator from "common/components/LoadingIndicator";

function DepartmentAdmin() {
	const { departments, error, status, fetchDepartments } = useDepartments();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	useEffect(() => {
		if (!departments.length) fetchDepartments();

		// eslint-disable-next-line
	}, []);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Departments"
				indicator={<LoadingIndicator loading={[status]} />}
			>
				<Button onClick={() => setShowCreateModal(!!!showCreateModal)}>
					Add New
				</Button>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<AdminDepartmentTable
				isLoading={status === "loading"}
				departments={departments}
			/>
			<DepartmentCreateModal
				visible={!!showCreateModal}
				closeModal={() => setShowCreateModal(false)}
			/>
		</TransitionedRoutePage>
	);
}

export default DepartmentAdmin;
