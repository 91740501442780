import _ from "jwt-decode";
import { TokenPayloadModel } from "services/user/user.model";

class TokenUtilsClass {
	decode = (token: string): TokenPayloadModel => _(token);
	save = (token: string) => localStorage.setItem("token", token);
	get = () => localStorage.getItem("token");
	delete = () => localStorage.removeItem("token");
}

const TokenUtils = new TokenUtilsClass();

export default TokenUtils;
