import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { UserLoggedInModel } from "services/user/user.model";

export interface rAuth extends UserLoggedInModel {
	error?: string;
	status: Status;
	isLogged?: boolean;
}
const initState: rAuth = {
	id: null,
	username: "",
	accessToken: "",
	role: null,
	error: "",
	status: "idle",
	isLogged: false,
};

// actions
const login: CaseReducer<rAuth, PayloadAction<UserLoggedInModel>> = (
	state,
	action
): rAuth => ({
	...state,
	...action.payload,
	status: "loaded",
	error: "",
	isLogged: true,
});
const logout: CaseReducer = (): rAuth => initState;
const loggingIn: CaseReducer<rAuth> = (state): rAuth => ({
	...state,
	status: "loading",
	error: "",
	isLogged: false,
});
const failed: CaseReducer<rAuth, PayloadAction<string>> = (
	state,
	action
): rAuth => ({
	...state,
	status: "hasError",
	error: action.payload,
	isLogged: false,
});

// slice
const authSlice = createSlice({
	name: "auth",
	initialState: initState,
	reducers: { login, logout, loggingIn, failed },
});

// exports
export const authActions = authSlice.actions;
export default authSlice;
