import remoteApi from "common/remote/api";
import { DepartmentData, DepartmentModel } from "./department.model";

export const onDepartmentGet = (): Promise<DepartmentModel[]> =>
	remoteApi.get("/department");

export const onDepartmentCreate = (
	payload: DepartmentData
): Promise<DepartmentModel> => remoteApi.post("/department", { ...payload });

export const onDepartmentUpdate = (
	id: number,
	payload: DepartmentData
): Promise<DepartmentModel> => remoteApi.patch(`/department/${id}`, payload);

export const onDepartmentDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/department/${id}`);
