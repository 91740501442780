import styled from "styled-components";

export const HomeSidebarContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;

	ul {
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		gap: 10px;

		li {
			list-style: none;
			font-weight: 500;
			border-bottom: 1px solid lightgrey;

			a {
				padding: 10px;
				display: block;

				font-family: var(--ff);
				color: var(--black);
				background-color: rgba(200, 200, 200, 0);
				transition: 0.15s linear;

				&.active {
					color: blue;
					border-color: blue;
				}

				&:hover {
					background-color: rgba(200, 200, 200, 0.4);
				}
			}
		}
	}
`;
