import React, { FC } from "react";
import { Button, Form, Input, InputNumber, Modal, ModalProps } from "antd";
import { GroupData, GroupModel } from "services/groups/groups.model";
import { onGroupUpdate } from "services/groups/groups.service";
import { groupActions } from "redux/reducers/group.redux";
import useEditRecord from "common/hooks/useEditRecord";
import { StyledForm } from "features/Admin/admin.style";
import SelectDepartment from "features/Admin/Departments/widgets/SelectDepartment";

interface GroupEditModalProps extends ModalProps {
	record: GroupModel | null;
	closeModal: () => void;
}

const GroupEditModal: FC<GroupEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		GroupData,
		GroupModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onGroupUpdate,
		action: groupActions.updateGroup,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit group"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as GroupData);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item
					name="groupName"
					label="Name"
					initialValue={record.groupName}
				>
					<Input type="text" />
				</Form.Item>
				<Form.Item name="year" label="Year" initialValue={record.year}>
					<InputNumber />
				</Form.Item>
				<Form.Item name="semester" label="Semester">
					<InputNumber />
				</Form.Item>
				<Form.Item name="numberOfStudents" label="Number of students">
					<InputNumber />
				</Form.Item>

				<SelectDepartment />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default GroupEditModal;
