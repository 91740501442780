import React, { FC, useEffect, useState } from "react";
import { Cascader, Form } from "antd";

import useInstructor from "../hooks/useInstructor";
import useDepartments from "features/Admin/Departments/hooks/useDepartments";
import { CascaderOptionType } from "antd/lib/cascader";

const SelectInstructor: FC<{}> = () => {
	const { instructors, fetchInstructors } = useInstructor();
	const { departments, fetchDepartments } = useDepartments();

	const [deptInsOptions, setDeptInsOptions] = useState<CascaderOptionType[]>(
		[]
	);

	useEffect(() => {
		if (!instructors || !instructors.length) fetchInstructors();
		if (!departments || !departments.length) fetchDepartments();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let brs = [];
		for (let dept of departments) {
			const deptRooms = instructors.filter(
				(ins) => ins.related_fields.department_id === dept.id
			);
			// eslint-disable-next-line
			if (deptRooms.length)
				brs.push({
					label: dept.deptName,
					value: dept.id,
					children: deptRooms.map<CascaderOptionType>((it) => ({
						label: it.instructorName,
						value: it.id,
					})),
				});
		}
		setDeptInsOptions(brs);
	}, [departments, instructors]);
	return (
		<Form.Item
			name="instructor_id"
			label="Instructor"
			rules={[
				{
					required: true,
					message: "Choose an instructor",
				},
			]}
		>
			<Cascader
				allowClear
				expandTrigger="hover"
				options={deptInsOptions}
				displayRender={(label) => label.join(" - ")}
			/>
			{/* <Select
				loading={status === "loading"}
				allowClear
				showSearch
				filterOption={(input, option) => {
					return (
						option?.children
							?.toLowerCase()
							?.indexOf(input.toLowerCase()) >= 0
					);
				}}
				placeholder="Choose a instructor"
			>
				{instructors.map((item, i) => (
					<Select.Option value={item.id} key={i}>
						{item.instructorName}
					</Select.Option>
				))}
			</Select> */}
		</Form.Item>
	);
};

export default SelectInstructor;
