import React from "react";
import styled from "styled-components";
import useUsers from "features/Admin/Users/hooks/useUsers";

import { List } from "antd";
import { useEffect } from "react";
import { weekdays } from "moment";
import { parseTime } from "common/utils/time";
import { RequestResultItem } from "services/requests/requests.model";

const CustomListItem = styled(List.Item)<{ active?: boolean }>`
	padding: 10px 5px;
	cursor: pointer;
	transition: 0.15s ease;
	background-color: ${(p) =>
		p.active ? "rgba(150, 150, 150, 0.2)" : "rgba(0, 0, 0, 0)"};
	&:hover {
		background-color: rgba(150, 150, 150, 0.4);
	}
`;

interface ListItemSidebarWidgetProps {
	onItemClick: (id: number) => void;
	item: RequestResultItem;
	active: number | null;
}

function ListItemSidebarWidget({
	onItemClick,
	item,
	active,
}: ListItemSidebarWidgetProps) {
	const { users, fetchUsers } = useUsers();

	useEffect(() => {
		if (!users.length) fetchUsers();
		// eslint-disable-next-line
	}, []);

	return (
		<CustomListItem
			active={item.id === active}
			onClick={() => onItemClick(item.id)}
		>
			<List.Item.Meta
				title={`${item.info.group} - ${item.info.subject}`}
				description={`${parseTime(item.startTime)} to ${parseTime(
					item.endTime
				)} on ${weekdays()[item.weekDay]} by ${
					users.find(
						(user) =>
							user.id === item.related_fields.requestedByUser_id
					)?.username || "anonymous"
				}`}
			/>
		</CustomListItem>
	);
}

export default ListItemSidebarWidget;
