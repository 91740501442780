import React, { FC } from "react";
import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";
import CPlaygroundMobileHeader from "common/widgets/CPlaygroundMobileHeader";
import useUI from "common/hooks/useUI";

const AdminPlaygroundWrapper = styled(Content)`
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	overflow: hidden scroll;
`;
interface PGContentProps {
	isTabletOrMobile: boolean;
}
const PGContent = styled.div<PGContentProps>`
	padding-top: ${(props) => (props.isTabletOrMobile ? "100px" : "0px")};
`;

interface CPlaygroundProps {}

const CPlayground: FC<CPlaygroundProps> = ({ children }) => {
	const {
		dimensions: { isTabletOrMobile },
	} = useUI();
	return (
		<AdminPlaygroundWrapper>
			<CPlaygroundMobileHeader />
			<PGContent isTabletOrMobile={isTabletOrMobile}>
				{children}
			</PGContent>
		</AdminPlaygroundWrapper>
	);
};

export default CPlayground;
