import React from "react";
import FallbackRoute from "common/components/FallbackRoute";
import Routine from "features/Home/Routines";
import Search from "features/Home/Search";
import { Route, Switch } from "react-router";

function UserRoutes() {
	return (
		<Switch>
			<Route path="/" exact />
			<Route path="/routines" exact component={Routine} />
			<Route path="/search" component={Search} />

			<Route path="*" component={FallbackRoute} />
		</Switch>
	);
}

export default UserRoutes;
