import React from "react";

import SidebarContent from "./SidebarContent";
import CSidebar from "common/components/CSidebar";
import { Route, Switch } from "react-router";
import SearchBox from "features/Home/Search/widgets/SearchBox";
import ViewRoutineSidebar from "features/Home/Routines/widgets/ViewRoutineSidebar";

function Sidebar() {
	return (
		<CSidebar>
			<Switch>
				<Route path="/routines" component={ViewRoutineSidebar} />
				<Route path="/search" component={SearchBox} />
				<Route path="*" component={SidebarContent} />
			</Switch>
		</CSidebar>
	);
}

export default Sidebar;
