import remoteApi from "common/remote/api";
import { UserData, UserLoginProps, UserModel } from "./user.model";

export const onUsersGet = (): Promise<UserModel[]> => remoteApi.get("/user");

export const onUserCreate = (payload: UserLoginProps): Promise<UserModel> =>
	remoteApi.post("/user", { ...payload });

export const onUserUpdate = (
	id: number,
	payload: UserData
): Promise<UserModel> => remoteApi.patch(`/user/${id}`, payload);

export const onUserDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/user/${id}`);
