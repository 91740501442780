import React from "react";
import { Route, Switch } from "react-router";

import RoomAdmin from "features/Admin/Room";
import BlockAdmin from "features/Admin/Blocks";
import UserAdmin from "features/Admin/Users";
import RoutineAdmin from "features/Admin/Routines";
import DepartmentAdmin from "features/Admin/Departments";
import SubjectAdmin from "features/Admin/Subjects";
import GroupAdmin from "features/Admin/Groups";
import InstructorAdmin from "features/Admin/Instructors";
import AdminWelcome from "features/Admin";

const AdminRoutes: React.FC = () => {
	return (
		<Switch>
			<Route path="/admin/" exact component={AdminWelcome} />
			<Route path="/admin/routines" component={RoutineAdmin} />
			<Route path="/admin/rooms" component={RoomAdmin} />
			<Route path="/admin/blocks" component={BlockAdmin} />
			<Route path="/admin/users" component={UserAdmin} />
			<Route path="/admin/departments" component={DepartmentAdmin} />
			<Route path="/admin/subjects" component={SubjectAdmin} />
			<Route path="/admin/groups" component={GroupAdmin} />
			<Route path="/admin/instructors" component={InstructorAdmin} />
		</Switch>
	);
};

export default AdminRoutes;
