import { message as msg } from "antd";
import { useSelector } from "react-redux";
import { groupActions } from "redux/reducers/group.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { onGroupGet } from "services/groups/groups.service";

const useGroups = () => {
	const { groups, error, status } = useSelector(
		(state: RootState) => state.groups
	);
	const dispatch = useAppDispatch();

	const fetchGroups = async () => {
		try {
			dispatch(groupActions.loading());
			const departsFromApi = await onGroupGet();
			dispatch(groupActions.addGroups(departsFromApi));
		} catch (ex) {
			const {
				message = "Could not fetch department data at the moment.",
			} = ex;
			dispatch(groupActions.failed(message));
			msg.error(message);
		}
	};

	const findByName = (groupName: string) =>
		groups.find((item) => item.groupName === groupName);

	const findById = (id: number) => groups.find((item) => item.id === id);

	return { groups, error, status, fetchGroups, findByName, findById };
};
export default useGroups;
