import React, { FC, ReactNode } from "react";
import { AdminPageHeaderWrapper } from "../admin.style";

interface AdminPageHeaderProps {
	page: string;
	actionButton?: ReactNode | JSX.Element | FC;
	indicator?: ReactNode | JSX.Element | FC;
}

const AdminPageHeader: FC<AdminPageHeaderProps> = ({
	page,
	actionButton,
	indicator,
	children,
}) => {
	return (
		<AdminPageHeaderWrapper>
			<h2>
				<span>{page}</span>
				{actionButton ? (
					<span style={{ marginLeft: 20 }}>{actionButton}</span>
				) : null}
				{indicator ? (
					<span style={{ marginLeft: 20 }}>{indicator}</span>
				) : null}
			</h2>
			<div className="actions">{children}</div>
		</AdminPageHeaderWrapper>
	);
};

export default AdminPageHeader;
