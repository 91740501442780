import React from "react";

import { Button, Space, Spin, Tooltip } from "antd";
import { VscRefresh } from "react-icons/vsc";
import { Loading3QuartersOutlined } from "@ant-design/icons";

interface HeaderSidebarWidgetProps {
	count: number;
	onRefresh: () => Promise<void>;
	status: Status;
}

function HeaderSidebarWidget({
	count,
	onRefresh,
	status,
}: HeaderSidebarWidgetProps) {
	return (
		<Space
			style={{
				width: "100%",
				justifyContent: "space-between",
			}}
		>
			<h4 style={{ margin: 0 }}>
				<b>
					Requests <span style={{ color: "green" }}>({count})</span>
				</b>
			</h4>
			<Tooltip placement="left" title="Refresh">
				<Button type="link" onClick={() => onRefresh()}>
					<Spin
						indicator={
							status === "loading" ? (
								<Loading3QuartersOutlined spin={false} />
							) : (
								<VscRefresh />
							)
						}
					/>
				</Button>
			</Tooltip>
		</Space>
	);
}

export default HeaderSidebarWidget;
