import React, { useState } from "react";
import useRooms from "features/Admin/Room/hooks/useRooms";
import { Button } from "antd";
import { RoomModel } from "services/room/room.model";
import { useEffect } from "react";
import { StyledModal } from "features/Admin/admin.style";
import { VscInfo } from "react-icons/vsc";

interface RoomInfoWidgetProps {
	room: number;
	header: string;
}

function RoomInfoWidget({ room, header }: RoomInfoWidgetProps) {
	const { rooms, fetchRooms } = useRooms();
	const [roomData, setRoomData] = useState<{
		data: RoomModel | null;
		visible: boolean;
	}>({
		data: null,
		visible: false,
	});

	useEffect(() => {
		setRoomData({
			data: rooms.find((item) => item.id === room) || null,
			visible: false,
		});
	}, [room, rooms]);

	useEffect(() => {
		if (!rooms.length) fetchRooms();
		// eslint-disable-next-line
	}, [rooms]);

	return (
		<>
			<Button
				style={{ padding: 0 }}
				type="link"
				onClick={() => {
					setRoomData({
						...roomData,
						visible: !roomData.visible,
					});
				}}
			>
				<VscInfo size={16} />
			</Button>
			{!roomData.data ? null : (
				<StyledModal
					centered
					title={"Room Info" + header}
					onCancel={() => {
						setRoomData({
							...roomData,
							visible: false,
						});
					}}
					visible={roomData.visible}
				>
					<span>
						Capacity: <b>{roomData.data.roomCapacity}</b>
					</span>
					<br />
					<span>
						Type: <b>{roomData.data.roomType}</b>
					</span>
					<br />
					<span>Features:</span>
					<ol>
						<li>
							Has projecter:{" "}
							<b>
								{roomData.data.projectorAvailable
									? "Yes"
									: "No"}
							</b>
						</li>
					</ol>
				</StyledModal>
			)}
		</>
	);
}

export default RoomInfoWidget;
