import moment from "moment";
import {
	RoutineEventModel,
	RoutineModel,
} from "services/routine/routine.model";
import { mapEventTime } from "./dates";

export const mapEvents = (
	thisDay: Date,
	rts?: RoutineModel[]
): RoutineEventModel[] => {
	return (
		rts?.map<RoutineEventModel>((item, i) => ({
			id: item.id,
			allDay: false,
			title: `${item.info.group} - ${item.info.subject}`,
			end: mapEventTime(item.endTime, thisDay),
			start: mapEventTime(item.startTime, thisDay),
		})) || []
	);
};

export const filterByGroup = (
	group: string,
	rts: RoutineModel[]
): RoutineModel[] => {
	const filtered = rts.filter((rt) => rt.info.group === group);
	return filtered;
};
export const filterByWeekDay = (date: Date, rts: RoutineModel[]) => {
	const weekDay = date.getDay();
	const formattedDt = moment(date).format("YYYY-MM-DD");
	const filtered = rts.filter((rt) => {
		if (rt.canceledOn && rt.canceledOn === formattedDt) return false;
		if (!rt.temporaryOn) {
			if (rt.weekDay === weekDay) return true;
			return false;
		} else if (rt.temporaryOn === formattedDt) {
			return true;
		}
		return false;
	});
	return filtered;
};
