import remoteApi from "common/remote/api";
import {
	CreateRoomService,
	GetEmptyRoomService,
	RoomData,
	RoomModel,
} from "./room.model";

export const onRoomsGet = (): Promise<RoomModel[]> => remoteApi.get("/room");

export const onOneRoomGet = (roomId: number): Promise<RoomModel> =>
	remoteApi.get(`/room/${roomId}`);

export const onEmptyRoomsGetNow = (): Promise<RoomModel[]> =>
	remoteApi.get("/room/emptynow");

export const onEmptyRoomsGetByTime = (
	hour: number,
	weekDay: number
): Promise<RoomModel[]> =>
	remoteApi.get("/room/emptynow", {
		params: {
			hour,
			weekDay,
		},
	});

export const onEmptyRoomsGet: GetEmptyRoomService = (
	params
): Promise<RoomModel[]> =>
	remoteApi.get("/room/empty", {
		params,
	});

export const onRoomsCreate: CreateRoomService = (payload) =>
	remoteApi.post("/room", payload);

export const onRoomUpdate = (
	id: number,
	payload: RoomData
): Promise<RoomModel> => remoteApi.patch(`/room/${id}`, payload);

export const onRoomDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/room/${id}`);
