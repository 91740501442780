import React, { FC } from "react";
import { BlockModel } from "services/block/block.model";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";

import BlockEditModal from "./BlockEditModal";
import useEditModal from "common/hooks/useEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";

import CDropdown from "common/components/CDropdown";
import useBlocks from "../hooks/useBlocks";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onBlockDelete } from "services/block/block.service";
import { blockActions } from "redux/reducers/blocks.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";

const tableModel: TableModelFunction<BlockModel> = (onEdit, onDelete) => [
	{
		name: "Name",
		selector: "blockName",
		cell: (block) => <span>{block.blockName}</span>,
		sortable: true,
	},
	{
		name: "Number",
		selector: "blockNumber",
		cell: (block) => <span>{block.blockNumber}</span>,
		sortable: true,
		center: true,
	},
	{
		name: "Actions",
		cell: (block) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							onEdit(block);
						}}
						onDeleteClick={() => {
							onDelete(block);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminRoomTableProps {
	blocks: BlockModel[];
	isLoading: boolean;
}

const AdminBlockTable: FC<AdminRoomTableProps> = ({ blocks, isLoading }) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<BlockModel>();

	const { blocks: blks } = useBlocks();
	const { visible, onFinish, openModal, name, closeModal } = useDeleteModal(
		onBlockDelete,
		blockActions.deleteBlock
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(block) => {
							const _blk = blks.find(
								(blk) => blk.id === block.id
							);
							openEditModal(_blk);
						},
						(block) => {
							openModal(
								block,
								`block record: ${block.blockName} - ${block.blockNumber}`
							);
						}
					) as any
				}
				data={blocks}
				isLoading={isLoading}
			/>
			<BlockEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ visible, name, closeModal, onFinish }} />
		</>
	);
};

export default AdminBlockTable;
