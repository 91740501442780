import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
	}
	:root {
		--ff: "Lexend", sans-serif;

		--fs-sm: 12px;
		--fs-md: 14px;
		--fs: 16px;
		--fs-l: 18px;
		--fs-xl: 24px;

		--lightgrey: #c0c0c0;
		--grey: #6f6f6f;

		--blue: #0C1A32;
		--deep-blue: #060D19;
	}
	body {
		margin: 0;
		padding: 0;

		height: 100vh;
		width: 100%;
	}
	#root,
	.App {
		width: 100%;
		height: 100%;
	}
	button {
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
	p {
		&.error {
			color: red;
		}
	}
`;

export default GlobalStyles;
