import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { RoutineModel } from "services/routine/routine.model";

interface rRoutine {
	routines: RoutineModel[];
	error?: string;
	status: Status;
}

const initState: rRoutine = {
	routines: [],
	error: "",
	status: "idle",
};

// actions
const appendRoutines: CaseReducer<rRoutine, PayloadAction<RoutineModel[]>> = (
	state,
	action
): rRoutine => ({
	status: "loaded",
	error: "",
	routines: [...state.routines, ...action.payload],
});

const updateRoutine: CaseReducer<
	rRoutine,
	PayloadAction<{ id: number; data: RoutineModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		routines: state.routines.map((routine) => {
			if (routine.id !== id) return routine;
			return { ...data, id };
		}),
	};
};
const addRoutines: CaseReducer<rRoutine, PayloadAction<RoutineModel[]>> = (
	_,
	action
): rRoutine => ({
	status: "loaded",
	error: "",
	routines: action.payload,
});
const deleteRoutine: CaseReducer<rRoutine, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	routines: state.routines.filter((rtn) => rtn.id !== action.payload.id),
});
const resetRoutines: CaseReducer = (): rRoutine => initState;
const loading: CaseReducer<rRoutine> = (state): rRoutine => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rRoutine, PayloadAction<string>> = (
	state,
	action
): rRoutine => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const routineSlice = createSlice({
	name: "routines",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addRoutines,
		appendRoutines,
		updateRoutine,
		resetRoutines,
		deleteRoutine,
	},
});

// exports
export const routineActions = routineSlice.actions;
export default routineSlice;
