import React, { FC, useEffect, useState } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import {
	Button,
	Col,
	DatePicker,
	Form,
	ModalProps,
	Row,
	TimePicker,
} from "antd";

import { routineActions } from "redux/reducers/routines.redux";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { RoutineData, RoutineModel } from "services/routine/routine.model";
import {
	onClassRequest,
	onRoutinesCreate,
} from "services/routine/routine.service";
import SelectSubject from "features/Admin/Subjects/widgets/SelectSubject";
import SelectGroup from "features/Admin/Groups/widgets/SelectGroup";
import SelectInstructor from "features/Admin/Instructors/widgets/SelectInstructor";
import SelectRoom from "features/Admin/Room/widgets/SelectRoom";

import moment from "moment";
import type { Moment } from "moment";
import CWeekDaySelect from "common/widgets/CWeekDaySelect";
import useCreateRoutine from "../hooks/useCreateRoutine";
import Checkbox from "antd/lib/checkbox/Checkbox";
import useAuth from "common/hooks/useAuth";

export interface RoutineCreateModalProps extends ModalProps {
	closeModal: () => void;
	userId?: number | null;
}

export type Meridian = "am" | "AM" | "pm" | "PM";

const RoutineCreateModal: FC<RoutineCreateModalProps> = ({
	title,
	closeModal,
	userId,
	...otherProps
}) => {
	const { context, showModal, setTempData } = useCreateRoutine();

	const group = context?.group || null;

	const [times, setTimes] = useState<[Moment, Moment]>([
		moment(context?.slot?.start),
		moment(context?.slot?.end),
	]);

	useEffect(() => {
		setTimes([moment(context?.slot?.start), moment(context?.slot?.end)]);
	}, [context]);

	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		RoutineData,
		RoutineModel
	>({
		serviceFunction: userId ? onClassRequest : onRoutinesCreate,
		action: routineActions.appendRoutines,
		onRecordCreated: () => {
			closeModal();
		},
	});

	useEffect(() => {
		setTempData({
			date: moment(),
			oneOff: false,
		});
		// eslint-disable-next-line
	}, []);

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	const onFinish = (values: any) => {
		if (!values["group_id"]) {
			values.group_id = group;
		}
		if (typeof values["room_id"] === "object")
			values.room_id = values["room_id"][values["room_id"].length - 1];

		if (typeof values["instructor_id"] === "object")
			values.instructor_id =
				values["instructor_id"][values["instructor_id"].length - 1];

		if (userId || context?.temporary.oneOff) {
			values.temporaryOn = context?.temporary.date.format("YYYY-MM-DD");
		}

		if (userId) values.requestedByUser_id = userId;

		const startTime = parseInt(times[0].format("hhmm"));
		const endTime = parseInt(times[1].format("hhmm"));

		console.log({ startTime, endTime });

		values.startTime =
			startTime +
			(times[0].format("a") === "pm" && startTime < 1200 ? 1200 : 0);
		values.endTime =
			endTime +
			(times[1].format("a") === "pm" && endTime < 1200 ? 1200 : 0);
		createRecord(values);
	};

	const { auth } = useAuth();

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title={
				auth.role === "admin" || auth.role === "blockadmin"
					? "Create Routine"
					: "Request Reserve"
			}
			width={400}
			onOk={onOk}
			centered
			footer={null}
			visible={showModal}
			destroyOnClose={true}
		>
			<StyledForm
				form={form}
				onFinish={onFinish}
				layout="vertical"
				preserve={false}
			>
				<SelectSubject />
				<Row gutter={10}>
					<Col span={9}>
						<SelectGroup
							{...(group ? { defaultValue: group } : {})}
						/>
					</Col>
					<Col span={15}>
						<SelectInstructor />
					</Col>
				</Row>
				<Row gutter={10}>
					<Col span={24}>
						<SelectRoom />
					</Col>
				</Row>
				<Row gutter={5}>
					<Col span={8}>
						<CWeekDaySelect
							day={
								context?.temporary.oneOff
									? context?.temporary.date.toDate()
									: context?.slot?.start || new Date()
							}
						/>
					</Col>
					<Col span={16}>
						<Form.Item label="Time">
							<TimePicker.RangePicker
								onChange={(val) => {
									setTimes(val as [Moment, Moment]);
								}}
								value={times}
								allowClear
								bordered={false}
								minuteStep={30}
								format="HH:mm A"
							/>
						</Form.Item>
					</Col>
				</Row>
				<div style={{ margin: "15px 0" }}>
					<Row>
						<Col span={8}>
							<Checkbox
								style={{
									marginBottom: context?.temporary.oneOff
										? 20
										: 5,
								}}
								checked={
									!userId
										? context?.temporary.oneOff
										: userId !== null
								}
								onChange={(e) => {
									setTempData({
										date:
											context?.temporary.date || moment(),
										oneOff: e.target.checked,
									});
								}}
							>
								Temporary
							</Checkbox>
						</Col>
						<Col span={16}>
							<DatePicker
								disabled={!userId && !context?.temporary.oneOff}
								style={{ width: "100%" }}
								showToday
								format="DD MMM yyyy, dddd"
								value={context?.temporary.date}
								onChange={(e) => {
									if (e)
										setTempData({
											date: e,
											oneOff:
												context?.temporary.oneOff ||
												false,
										});
								}}
							/>
						</Col>
					</Row>
				</div>
				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading"
							? "Creating"
							: auth.role === "admin" ||
							  auth.role === "blockadmin"
							? "Create"
							: "Request"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default RoutineCreateModal;
