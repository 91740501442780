import React from "react";
import { AdminWelcomeWrapper, TransitionedRoutePage } from "./admin.style";

function AdminWelcome() {
	return (
		<TransitionedRoutePage>
			<AdminWelcomeWrapper>
				<div className="content">
					<h2>Welcome to admin panel.</h2>
					<p>Get started from sidebar on the left.</p>
				</div>
			</AdminWelcomeWrapper>
		</TransitionedRoutePage>
	);
}

export default AdminWelcome;
