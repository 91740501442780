import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { recordDeleteActions } from "redux/reducers/record.delete.redux";
import { RootState, useAppDispatch } from "redux/store.redux";

export function useDeleteModal<T>(
	serviceFunction: (id: number) => Promise<any>,
	action: null | ActionCreatorWithPayload<{ id: number }>,
	onDelete?: () => void | null
) {
	const modalState = useSelector((state: RootState) => state.recordDelete);

	const dispatch = useAppDispatch();

	const openModal = (record: T, name: string) => {
		console.log("");
		dispatch(recordDeleteActions.open({ record, name }));
	};
	const closeModal = () => dispatch(recordDeleteActions.close());

	const onFinish = async () => {
		try {
			await serviceFunction(modalState.record.id as number);
			action && dispatch(action({ id: modalState.record.id as number }));
			onDelete && onDelete();
			closeModal();
			notification.success({
				message: "Record deleted successfully.",
			});
		} catch (ex) {
			throw ex;
		}
	};

	return { openModal, closeModal, onFinish, ...modalState };
}
