import React, { FC } from "react";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";
import DepartmentEditModal from "./DepartmentEditModal";
import useEditModal from "common/hooks/useEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import { DepartmentModel } from "services/departments/department.model";
import useDepartments from "../hooks/useDepartments";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onDepartmentDelete } from "services/departments/department.service";
import { departmentActions } from "redux/reducers/departments.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";

const tableModel: TableModelFunction<DepartmentModel> = (onEdit, onDelete) => [
	{
		name: "Name",
		selector: "deptName",
		cell: (depart) => <span>{depart.deptName}</span>,
		sortable: true,
	},
	{
		name: "Actions",
		cell: (dept) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							onEdit(dept);
						}}
						onDeleteClick={() => {
							onDelete(dept);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminDepartmentTableProps {
	departments: DepartmentModel[];
	isLoading: boolean;
}

const AdminDepartmentTable: FC<AdminDepartmentTableProps> = ({
	departments,
	isLoading,
}) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<DepartmentModel>();

	const { departments: depts } = useDepartments();
	const { visible, openModal, closeModal, onFinish, name } = useDeleteModal(
		onDepartmentDelete,
		departmentActions.deleteDepartment
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(d) => {
							const _dept = depts.find(
								(dept) => dept.id === d.id
							);
							openEditModal(_dept);
						},
						(d) => {
							openModal(d, `department record: ${d.deptName}`);
						}
					) as any
				}
				data={departments}
				isLoading={isLoading}
			/>
			<DepartmentEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ onFinish, closeModal, visible, name }} />
		</>
	);
};

export default AdminDepartmentTable;
