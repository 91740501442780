import { RootState, useAppDispatch } from "redux/store.redux";
import { useSelector } from "react-redux";
import { RoutineModel } from "services/routine/routine.model";
import { routineViewActions } from "redux/reducers/routine.view.redux";
import { onRoutineGetByRoom } from "services/routine/routine.service";

const useRoutineView = () => {
	const routineViewProperties = useSelector(
		(state: RootState) => state.routineView
	);

	const dispatch = useAppDispatch();

	const changeGroup = (group: string, routines: RoutineModel[]) => {
		dispatch(routineViewActions.setGroupAndRoutines({ routines, group }));
	};
	const changeRoom = async (room: number) => {
		try {
			const routines = await onRoutineGetByRoom(room);
			changeShow("room");
			dispatch(
				routineViewActions.setRoomAndRoutines({
					routines,
					room,
				})
			);
		} catch (ex) {}
	};
	const changeView = (view: string) => {
		dispatch(routineViewActions.changeView(view as RoutineView));
	};
	const changeShow = (view: RoutineShow) => {
		dispatch(routineViewActions.changeShow(view));
	};
	const changeDate = (date: Date) => {
		dispatch(routineViewActions.changeDate(date));
	};
	const reset = (permission: number) => {
		if (permission === 0) dispatch(routineViewActions.reset());
	};

	// all return
	return {
		...routineViewProperties,
		changeGroup,
		changeDate,
		changeView,
		changeRoom,
		changeShow,
		reset,
	};
};

export default useRoutineView;
