import { Button, Input } from "antd";
import styled from "styled-components";

export const LoginSchoolLogo = styled.div`
	flex: 0 0 60%;
	@media (max-width: 1024px) {
		flex: 0 0 40%;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: var(--ff);

	img {
		width: 100%;
		max-width: 160px;
	}
`;

export const LoginFormWrapper = styled.div`
	flex: 0 0 40%;
	border-left: 1px solid var(--lightgrey);

	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 1024px) {
		flex: 0 0 60%;
		border-left: 0px solid var(--lightgrey);
		width: 100%;
		max-width: 600px;
		margin: 0 auto;

		justify-content: flex-start;
	}

	padding-left: 20px;

	font-family: var(--ff);
`;

export const LoginFormHeaderWrapper = styled.div`
	margin-bottom: 10px;
`;
export const LoginFormHeader = styled.h2`
	margin: 0;
`;
export const LoginFormSubHeader = styled.p`
	color: var(--grey);
	font-size: var(--fs-md);
`;

export const LoginFormContainer = styled.div`
	margin: 30px 0;
	width: 90%;
	max-width: 600px;
`;
export const LoginInput = styled(Input)`
	height: 40px;
	border: 1px solid var(--grey);

	font-family: var(--ff);
`;
export const LoginButton = styled(Button)`
	width: 100%;
	height: 40px;

	font-weight: 600;
	font-family: var(--ff);
`;

export const LoginErrorMessage = styled.p`
	color: red;
	font-size: var(--fs-md);
`;
