import { message as msg } from "antd";

import { RootState } from "redux/store.redux";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "redux/reducers/users.redux";

import { onUsersGet } from "services/user/user.service";

const useUsers = () => {
	const { users, error, status } = useSelector(
		(state: RootState) => state.users
	);
	const dispatch = useDispatch();

	const fetchUsers = async () => {
		try {
			dispatch(userActions.loading());
			const usersFromApi = await onUsersGet();
			dispatch(userActions.addUsers(usersFromApi));
		} catch (ex) {
			const { message = "Could not fetch block data at the moment." } =
				ex;
			dispatch(userActions.failed(message));
			msg.error(message);
		}
	};

	return { users, error, status, fetchUsers };
};
export default useUsers;
