import { RootState } from "redux/store.redux";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import TokenUtils from "common/utils/token";
import { authActions } from "redux/reducers/auth.redux";

import { login } from "services/user/login.service";
import { UserLoginProps } from "services/user/user.model";
import { useHistory } from "react-router-dom";

export const useAuth = () => {
	const dispatch = useDispatch();
	const auth = useSelector((state: RootState) => state.auth);
	const history = useHistory();

	const logUserIn = async (payload: UserLoginProps) => {
		const key = "login";
		try {
			message.loading({
				content: "Loggin in...",
				key,
			});

			dispatch(authActions.loggingIn());

			const { accessToken } = await login(payload);
			const decoded = TokenUtils.decode(accessToken);
			TokenUtils.save(accessToken);

			dispatch(
				authActions.login({
					...decoded,
					accessToken,
				})
			);
			message.success({ content: "You're logged in successfully.", key });
		} catch (error: any) {
			dispatch(
				authActions.failed(error.message || "Something went wrong.")
			);
			message.error({
				content: "Sorry. Login failed. Check your credentials.",
				key,
			});
		}
	};
	const logUserOut = () => {
		TokenUtils.delete();
		dispatch(authActions.logout());
		message.info("You've been logged out.");
		history.replace("/");
	};

	return { auth, logUserIn, logUserOut };
};

export default useAuth;
