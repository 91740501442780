import React, { FC } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Form, Input, InputNumber, ModalProps } from "antd";

import { onBlocksCreate } from "services/block/block.service";

import { blockActions } from "redux/reducers/blocks.redux";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { BlockData, BlockModel } from "services/block/block.model";

export interface BlockCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const BlockCreateModal: FC<BlockCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		BlockData,
		BlockModel
	>({
		serviceFunction: onBlocksCreate,
		action: blockActions.appendBlocks,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Create block"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					createRecord(values as BlockData);
				}}
				layout="vertical"
			>
				<Form.Item
					name="blockNumber"
					label="Block Number"
					rules={[
						{
							required: true,
							message: "Block number is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="blockName"
					label="BlockName"
					rules={[
						{
							required: true,
							message: "Name of block is required.",
						},
					]}
				>
					<Input />
				</Form.Item>
				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default BlockCreateModal;
