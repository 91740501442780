import React, { FC } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Form, Input, InputNumber, ModalProps } from "antd";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { onGroupCreate } from "services/groups/groups.service";
import { groupActions } from "redux/reducers/group.redux";

import { GroupData, GroupModel } from "services/groups/groups.model";
import SelectDepartment from "features/Admin/Departments/widgets/SelectDepartment";

export interface GroupCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const GroupCreateModal: FC<GroupCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		GroupData,
		GroupModel
	>({
		serviceFunction: onGroupCreate,
		action: groupActions.appendGroups,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Create group"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					createRecord(values as GroupData);
				}}
				layout="vertical"
			>
				<Form.Item
					name="groupName"
					label="Name"
					rules={[
						{
							required: true,
							message: "Group name is required.",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>
				<Form.Item
					name="year"
					label="Year"
					rules={[
						{
							required: true,
							message: "Group year is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="semester"
					label="Semester"
					rules={[
						{
							required: true,
							message: "Semester year is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="numberOfStudents"
					label="Number of students"
					rules={[
						{
							required: true,
							message: "Number of students is required.",
						},
					]}
				>
					<InputNumber />
				</Form.Item>

				<SelectDepartment />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default GroupCreateModal;
