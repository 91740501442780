import { message as msg } from "antd";
import { useSelector } from "react-redux";
import { instructorActions } from "redux/reducers/instructor.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { onInstructorGet } from "services/instructor/instructor.service";

const useInstructor = () => {
	const { instructors, error, status } = useSelector(
		(state: RootState) => state.instructors
	);
	const dispatch = useAppDispatch();

	const fetchInstructors = async () => {
		try {
			dispatch(instructorActions.loading());
			const instructorsFromApi = await onInstructorGet();
			dispatch(instructorActions.addInstructors(instructorsFromApi));
		} catch (ex) {
			const {
				message = "Could not fetch department data at the moment.",
			} = ex;
			dispatch(instructorActions.failed(message));
			msg.error(message);
		}
	};

	return { instructors, error, status, fetchInstructors };
};
export default useInstructor;
