import React, { FC } from "react";
import { Dropdown, DropDownProps } from "antd";

interface CDropdownProps extends DropDownProps {}

const CDropdown: FC<CDropdownProps> = ({ children, ...props }) => {
	return (
		<Dropdown
			overlayStyle={{
				boxShadow: "0px 0px 15px rgba(100, 100, 100, 0.25)",
			}}
			placement="topLeft"
			{...props}
		>
			{children}
		</Dropdown>
	);
};

export default CDropdown;
