import React, { FC, useEffect, useState } from "react";
import { Calendar, momentLocalizer, CalendarProps } from "react-big-calendar";
import moment from "moment";
import {
	RoutineEventModel,
	RoutineModel,
} from "services/routine/routine.model";

import "react-big-calendar/lib/css/react-big-calendar.css";
import RoutineCreateModal from "./RoutineCreateModal";
import RoutineEditModal from "./RoutineEditModal";
import {
	editRoutineContext,
	editRoutineContextData,
} from "../contexts/EditRoutineContext";
import { SlotInfo } from "redux/reducers/routine.create.redux";
import useCreateRoutine from "../hooks/useCreateRoutine";
import useRoutineView from "../hooks/useRoutineView";
import useGroups from "features/Admin/Groups/hooks/useGroups";
import { CalendarContainer } from "../routine.admin.style";

const localizer = momentLocalizer(moment); // or globalizeLocalizer

interface RoutineCalendarProps {
	routine: RoutineModel[];
	group?: number;
}

const RoutineCalendar: FC<RoutineCalendarProps> = ({ routine, group }) => {
	const [selectedEventData, setSelectedEventData] =
		useState<editRoutineContextData>({
			showModal: false,
			slot: null,
		});
	const { createRoutine, cancelCreateRoutine } = useCreateRoutine();
	const { changeView, changeGroup, changeDate, toView } = useRoutineView();
	const { findById } = useGroups();

	// effects
	useEffect(() => {
		const groupData = findById(group || 0);
		changeGroup(groupData?.groupName || "", routine);
		// eslint-disable-next-line
	}, [routine, group]);

	const opts: CalendarProps = {
		step: 60,
		events: toView,
		selectable: true,
		timeslots: 1,
		endAccessor: "end",
		defaultView: "week",
		drilldownView: "day",
		startAccessor: "start",
		localizer,
		views: ["day", "week"],
		style: { height: "calc(100vh - 80px)" },
		defaultDate: new Date(),
	};

	return (
		<CalendarContainer>
			<Calendar
				{...opts}
				onNavigate={(newDate) => {
					changeDate(newDate);
				}}
				onView={changeView}
				onSelectEvent={(er) => {
					setSelectedEventData({
						showModal: true,
						slot: er as RoutineEventModel,
					});
				}}
				onSelectSlot={(ev) => {
					createRoutine(ev as SlotInfo, group || 0);
				}}
			/>
			<RoutineCreateModal closeModal={cancelCreateRoutine} />
			<editRoutineContext.Provider value={{ ...selectedEventData }}>
				<RoutineEditModal
					closeModal={() => {
						setSelectedEventData({
							showModal: false,
							slot: null,
						});
					}}
				/>
			</editRoutineContext.Provider>
		</CalendarContainer>
	);
};

export default RoutineCalendar;
