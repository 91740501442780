import React from "react";
import { LoginSchoolLogo } from "../loginwidget.style";

import logo from "common/assets/ku-logo.png";

const LoginSchoolLogoWidget: React.FC = () => {
	return (
		<LoginSchoolLogo>
			<img src={logo} alt="KU Logo" />
			<h1>Kathmandu University</h1>
		</LoginSchoolLogo>
	);
};

export default LoginSchoolLogoWidget;
