import { message, notification } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { requestActions } from "redux/reducers/requests.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import {
	onRequestApprove,
	onRequestFetch,
} from "services/requests/requests.service";
import { onRoutineDelete } from "services/routine/routine.service";

const useRequests = () => {
	const {
		lists: requestList,
		active,
		status,
		error,
		requesting,
	} = useSelector((state: RootState) => state.request);
	const dispatch = useAppDispatch();

	const deleteRequest = (id: number) =>
		dispatch(requestActions.deleteRequest(id));

	const fetchRequests = async () => {
		try {
			dispatch(requestActions.loading());

			const requests = await onRequestFetch();

			dispatch(requestActions.addRequests(requests));
		} catch (ex) {
			dispatch(requestActions.failed("Failed to load."));
			message.error("Could not fetch requests at the moment. Reload.");
		}
	};
	const changeActive = (id: number) =>
		dispatch(requestActions.changeActive(id));

	const approveRequest = async (id: number) => {
		try {
			dispatch(requestActions.requesting());
			const resp = await onRequestApprove(id);

			dispatch(requestActions.deleteRequest(resp.id));
			dispatch(requestActions.requestComplete());

			notification.success({
				message: "Request approved.",
			});
		} catch (ex) {
			dispatch(requestActions.requestFailed());
			message.error("Could not approve at the moment.");
		}
	};

	const dismissRequest = async (id: number) => {
		try {
			dispatch(requestActions.requesting());
			await onRoutineDelete(id);

			dispatch(requestActions.deleteRequest(id));
			dispatch(requestActions.requestComplete());

			notification.success({
				message: "Request discarded.",
			});
		} catch (ex) {
			dispatch(requestActions.requestFailed());
			message.error("Could not discard at the moment.");
		}
	};

	useEffect(() => {
		if (!requestList.length) fetchRequests();
		// eslint-disable-next-line
	}, []);

	return {
		requestList,
		status,
		requesting,
		error,
		active,
		fetchRequests,
		deleteRequest,
		changeActive,
		approveRequest,
		dismissRequest,
	};
};

export default useRequests;
