import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Form, message, notification } from "antd";
import { useState } from "react";
import { useAppDispatch } from "redux/store.redux";

interface useCreateNewRecordProps<T, M> {
	serviceFunction: (payload: T) => Promise<M>;
	action: ActionCreatorWithPayload<M[]>;
	onRecordCreated?: (record: M) => void;
}

function useCreateNewRecord<T, M>({
	serviceFunction,
	action,
	onRecordCreated,
}: useCreateNewRecordProps<T, M>) {
	const [formState, setFormState] = useState<{
		status: Status;
		error?: string;
	}>({ status: "idle", error: "" });

	const [form] = Form.useForm<T>();
	const dispatch = useAppDispatch();

	const createRecord = async (values: T) => {
		try {
			setFormState({ status: "loading" });

			const res = await serviceFunction(values);

			notification.success({
				message: "New record created successfully.",
			});

			dispatch(action([res]));
			form.resetFields();

			onRecordCreated && onRecordCreated(res);
			setFormState({ status: "loaded" });
		} catch (ex) {
			setFormState({ status: "hasError", error: ex.message });
			message.error("There was an error while creating new record.");
		}
	};

	return {
		form,
		createRecord,
		formStatus: formState.status,
		error: formState.error,
	};
}

export default useCreateNewRecord;
