import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { GroupModel } from "services/groups/groups.model";

interface rGroup {
	groups: GroupModel[];
	error?: string;
	status: Status;
}

const initState: rGroup = {
	groups: [],
	error: "",
	status: "idle",
};

// actions
const appendGroups: CaseReducer<rGroup, PayloadAction<GroupModel[]>> = (
	state,
	action
): rGroup => ({
	status: "loaded",
	error: "",
	groups: [...state.groups, ...action.payload],
});

const updateGroup: CaseReducer<
	rGroup,
	PayloadAction<{ id: number; data: GroupModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		groups: state.groups.map((grp) => {
			if (grp.id !== id) return grp;
			return { ...data, id };
		}),
	};
};
const addGroups: CaseReducer<rGroup, PayloadAction<GroupModel[]>> = (
	_,
	action
): rGroup => ({
	status: "loaded",
	error: "",
	groups: action.payload,
});
const deleteGroup: CaseReducer<rGroup, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	groups: state.groups.filter((group) => group.id !== action.payload.id),
});
const resetGroups: CaseReducer = (): rGroup => initState;
const loading: CaseReducer<rGroup> = (state): rGroup => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rGroup, PayloadAction<string>> = (
	state,
	action
): rGroup => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const groupSlice = createSlice({
	name: "group",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addGroups,
		appendGroups,
		updateGroup,
		resetGroups,
		deleteGroup,
	},
});

// exports
export const groupActions = groupSlice.actions;
export default groupSlice;
