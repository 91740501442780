import React from "react";
import styled from "styled-components";
import useSearch from "../hooks/useSearch";
import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";
import GoBackButton from "common/widgets/GoBackButton";
import AdminPageHeader from "features/Admin/widgets/PageHeader";
import CTimeInputSelect from "common/widgets/CTimeInputSelect";

import { useForm } from "antd/lib/form/Form";
import { TransitionedRoutePage } from "features/Admin/admin.style";
import { Button, Checkbox, Form, InputNumber, Select } from "antd";

const CustomFormItem = styled(Form.Item)`
	margin-bottom: 10px;

	.ant-col {
		&.ant-form-item-label {
			padding: 0 0 2px;
		}
	}
`;

const Wrapper = styled(TransitionedRoutePage)`
	padding: 10px 15px;
`;

function SearchBox() {
	const week = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	const [searchForm] = useForm();
	const { searchEmptyRooms } = useSearch();

	const submitSearchForm = (values: any) => {
		if (!values["weekDay"]) values.weekDay = new Date().getDay();
		if (!values["hour"]) values.hour = 900;
		if (values["projectorAvailable"] === false)
			delete values["projectorAvailable"];
		searchEmptyRooms(values as object);
	};
	return (
		<Wrapper>
			<AdminPageHeader page="Find Vacant Rooms">
				<GoBackButton />
			</AdminPageHeader>
			<div>
				<Form
					form={searchForm}
					onFinish={submitSearchForm}
					layout="vertical"
				>
					<SelectBlock
						placeholder="Search all blocks"
						margin="0 0 10px"
						name="blockId"
					/>

					<CustomFormItem name="roomType" label="Type of room">
						<Select
							style={{ width: "100%" }}
							allowClear
							placeholder="All types"
						>
							<Select.Option value="lecture">
								Lecture
							</Select.Option>
							<Select.Option value="lab">Lab</Select.Option>
						</Select>
					</CustomFormItem>

					<CTimeInputSelect
						label="Class Start Time"
						defaultValue={900}
						name="hour"
					/>

					<CustomFormItem name="weekDay" label="Day of week">
						<Select
							defaultValue={new Date().getDay()}
							style={{ width: "100%" }}
						>
							{week.map((item, i) => (
								<Select.Option key={item + i} value={i}>
									{item}
								</Select.Option>
							))}
						</Select>
					</CustomFormItem>
					<CustomFormItem
						name="roomCapacity"
						label="No. of students (classroom capacity)"
					>
						<InputNumber
							min={0}
							placeholder="Leave empty for all capacities"
							style={{ width: "100%" }}
						/>
					</CustomFormItem>

					<CustomFormItem
						name="projectorAvailable"
						valuePropName="checked"
					>
						<Checkbox>Need a projector</Checkbox>
					</CustomFormItem>

					<CustomFormItem>
						<Button type="primary" htmlType="submit">
							Search
						</Button>
					</CustomFormItem>
				</Form>
			</div>
		</Wrapper>
	);
}

export default SearchBox;
