import remoteApi from "common/remote/api";
import { BlockData, BlockModel } from "./block.model";

export const onBlocksGet = (): Promise<BlockModel[]> => remoteApi.get("/block");

export const onBlocksCreate = (payload: BlockData): Promise<BlockModel> =>
	remoteApi.post("/block", payload);

export const onBlockUpdate = (
	id: number,
	payload: BlockData
): Promise<BlockModel> => remoteApi.patch(`/block/${id}`, payload);

export const onBlockDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/block/${id}`);
