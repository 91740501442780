import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { api_config } from "common/configs/api_config";

const remoteApi = Axios.create({
	baseURL: api_config.API_BASEURL,
	timeout: 1000 * 10, // 10 seconds
});

remoteApi.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const token = localStorage.getItem("token");

		if (token)
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${token}`,
			};

		return config;
	},
	(error: AxiosError) => Promise.reject(error)
);

remoteApi.interceptors.response.use(
	(response: AxiosResponse) => response.data,
	(error: AxiosError) => Promise.reject(error)
);

export default remoteApi;
