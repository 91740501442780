import React, { useEffect } from "react";
import styled from "styled-components";
import GoBackButton from "common/widgets/GoBackButton";
import AdminPageHeader from "features/Admin/widgets/PageHeader";

import { TransitionedRoutePage } from "features/Admin/admin.style";
import { Tabs, Select } from "antd";
import useGroups from "features/Admin/Groups/hooks/useGroups";
import useRoutines from "../hooks/useRoutines";
import useRoutineView from "../hooks/useRoutineView";
import { filterByGroup } from "common/utils/routines";
import useBlocks from "features/Admin/Blocks/hooks/useBlocks";
import useRooms from "features/Admin/Room/hooks/useRooms";
import { RoomModel } from "services/room/room.model";
import { RoomListItem } from "../routine.admin.style";
import useUI from "common/hooks/useUI";

const Wrapper = styled(TransitionedRoutePage)`
	padding: 10px 15px;
`;

function ViewRoutineSidebar() {
	const { toggleSidebarCollapse } = useUI();
	const { groups, findById, fetchGroups, status: groupStatus } = useGroups();
	const { blocks, fetchBlocks } = useBlocks();
	const { rooms, fetchRooms } = useRooms();

	const { routines, fetchRoutines } = useRoutines();
	const {
		changeGroup,
		changeShow,
		changeRoom,
		showBy,
		room: activeRoomId,
	} = useRoutineView();

	useEffect(() => {
		if (!routines.length) fetchRoutines();
		if (!groups.length) fetchGroups();
		if (!blocks.length) fetchBlocks();
		if (!rooms.length) fetchRooms();
		// eslint-disable-next-line
	}, []);

	const onGroupSelect = (val: number) => {
		const selectedGroup = findById(val);
		if (!selectedGroup) return;
		changeGroup(
			selectedGroup.groupName,
			filterByGroup(selectedGroup.groupName, routines)
		);
		toggleSidebarCollapse();
	};

	const onRoomSelect = (roomData: RoomModel) => {
		changeRoom(roomData.id);
		toggleSidebarCollapse();
	};

	return (
		<Wrapper>
			<AdminPageHeader page="View Routines">
				<GoBackButton />
			</AdminPageHeader>
			<div>
				<Tabs
					activeKey={showBy}
					onTabClick={(v) => {
						changeShow(v as RoutineShow);
					}}
				>
					<Tabs.TabPane tab="By Group" key="group">
						<p>Choose a group:</p>
						<Select
							loading={groupStatus === "loading"}
							style={{ width: 100 }}
							onChange={onGroupSelect}
							placeholder="Choose"
						>
							{groups.map((item, i) => (
								<Select.Option
									key={item.groupName + i}
									value={item.id}
								>
									{item.groupName}
								</Select.Option>
							))}
						</Select>
					</Tabs.TabPane>
					<Tabs.TabPane tab="By Room" key="room">
						{blocks.map((item, i) => {
							const blockWiseRooms = rooms.filter(
								(it) => it.related_fields.block_id === item.id
							);

							if (!blockWiseRooms || !blockWiseRooms.length)
								return "";

							return (
								<div key={"block-" + i}>
									<h3>{item.blockName}</h3>
									<ul>
										{blockWiseRooms.map((room, ii) => (
											<RoomListItem
												key={"block-room-" + ii}
												className={
													activeRoomId &&
													activeRoomId === room.id
														? "active"
														: ""
												}
												onClick={() => {
													onRoomSelect(room);
												}}
											>
												{room.roomNumber}
											</RoomListItem>
										))}
									</ul>
								</div>
							);
						})}
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Wrapper>
	);
}

export default ViewRoutineSidebar;
