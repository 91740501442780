import React, { FC } from "react";
import { Button, Form, Input, Modal, ModalProps } from "antd";
import {
	DepartmentData,
	DepartmentModel,
} from "services/departments/department.model";
import useEditRecord from "common/hooks/useEditRecord";
import { departmentActions } from "redux/reducers/departments.redux";
import { onDepartmentUpdate } from "services/departments/department.service";
import { StyledForm } from "features/Admin/admin.style";
// import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";

interface DepartmentEditModalProps extends ModalProps {
	record: DepartmentModel | null;
	closeModal: () => void;
}

const DepartmentEditModal: FC<DepartmentEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		DepartmentData,
		DepartmentModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onDepartmentUpdate,
		action: departmentActions.updateDepartment,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit Block"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as DepartmentData);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item
					name="deptName"
					label="Name"
					initialValue={record.deptName}
					rules={[
						{
							required: true,
							message: "Department name is required.",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default DepartmentEditModal;
