import React, { FC, useEffect, useState } from "react";
import { Cascader, Form } from "antd";

import useRooms from "../hooks/useRooms";
import useBlocks from "features/Admin/Blocks/hooks/useBlocks";
import { CascaderOptionType } from "antd/lib/cascader";

const SelectRoom: FC<{}> = () => {
	const { rooms, fetchRooms } = useRooms();
	const { blocks, fetchBlocks } = useBlocks();

	const [blockRoomOptions, setBlockRoomsOptions] = useState<
		CascaderOptionType[]
	>([]);

	useEffect(() => {
		if (!rooms || !rooms.length) fetchRooms();
		if (!blocks || !blocks.length) fetchBlocks();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let brs = [];
		for (let block of blocks) {
			const blockRooms = rooms.filter(
				(room) => room.related_fields.block_id === block.id
			);
			// eslint-disable-next-line
			if (blockRooms.length)
				brs.push({
					label: block.blockName,
					value: block.id,
					children: blockRooms.map<CascaderOptionType>((it) => ({
						label: it.roomNumber,
						value: it.id,
					})),
				});
		}
		setBlockRoomsOptions(brs);
	}, [blocks, rooms]);

	return (
		<Form.Item
			name="room_id"
			label="Room"
			rules={[
				{
					required: true,
					message: "Choose a room",
				},
			]}
		>
			<Cascader
				allowClear
				expandTrigger="hover"
				options={blockRoomOptions}
				displayRender={(label) => label.join(" - ")}
			/>
		</Form.Item>
	);
};

export default SelectRoom;
