import React, { FC } from "react";
import styled from "styled-components";

import DataTable, {
	IDataTableProps,
	IDataTableColumn,
} from "react-data-table-component";

const StyledAdminDataTable = styled(DataTable)`
	margin-top: 10px;

	div {
		&[role="gridcell"] {
			span {
				&.actions {
					border: 1px solid rgba(0, 0, 0, 0);
					cursor: pointer;
					padding: 1px 4px;
					transition: 0.15s linear;

					&:hover {
						border: 1px solid #a2a2a2;
					}
				}
			}
		}
	}
`;

export interface TableModelFunction<T> {
	(
		onEdit: (record: T) => void,
		onDelete: (record: T) => void
	): IDataTableColumn<T>[];
}

export interface CDataTableProps extends IDataTableProps {
	isLoading?: boolean;
}

const CDataTable: FC<CDataTableProps> = ({ isLoading, ...otherProps }) => {
	return (
		<StyledAdminDataTable
			{...otherProps}
			pagination={false}
			progressPending={isLoading}
			highlightOnHover
			noHeader
			responsive
		/>
	);
};

export default CDataTable;
