import { message as msg } from "antd";
import { useSelector } from "react-redux";
import { routineActions } from "redux/reducers/routines.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { RoutineModel } from "services/routine/routine.model";
import { onRoutinesGet } from "services/routine/routine.service";

const useRoutines = () => {
	const { routines, error, status } = useSelector(
		(state: RootState) => state.routines
	);
	const dispatch = useAppDispatch();

	const fetchRoutines = async () => {
		try {
			dispatch(routineActions.loading());
			const routinesFromApi = await onRoutinesGet();
			dispatch(routineActions.addRoutines(routinesFromApi));
		} catch (ex) {
			const { message = "Could not fetch routine data at the moment." } =
				ex;
			dispatch(routineActions.failed(message));
			msg.error(message);
		}
	};

	const findbyId = (id: number) => {
		return routines.find((record) => record.id === id);
	};

	const sortByStartTime = (rts?: RoutineModel[]): RoutineModel[] => {
		if (!rts || !rts.length) rts = routines;
		const sorted = rts.sort((rt1, rt2) => rt1.startTime - rt2.startTime);
		return sorted;
	};

	return {
		routines,
		error,
		status,
		fetchRoutines,
		sortByStartTime,
		findbyId,
	};
};
export default useRoutines;
