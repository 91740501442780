import React from "react";
import { AdminViewWrapper } from "./adminview.style";
import AdminSidebar from "features/Admin/widgets/Sidebar.admin";
import AdminPlayground from "features/Admin/widgets/Playground.admin";

function AdminView() {
	return (
		<AdminViewWrapper>
			<AdminSidebar />
			<AdminPlayground />
		</AdminViewWrapper>
	);
}

export default AdminView;
