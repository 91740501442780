import React, { FC } from "react";
import { Button, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router";
import useAuth from "common/hooks/useAuth";
import { VscSettingsGear } from "react-icons/vsc";

interface DashboardSwitchButtonProps {
	size?: number;
}

const DashboardSwitchButton: FC<DashboardSwitchButtonProps> = ({ size }) => {
	const { auth } = useAuth();

	const { pathname } = useLocation();
	const history = useHistory();

	const swicthPanels = () => {
		// e.preventDefault();
		if (pathname.startsWith("/admin")) history.push("/");
		else history.push("/admin");
	};

	return auth.role === "admin" || auth.role === "blockadmin" ? (
		<Tooltip title="Switch between dashboards." placement="right">
			<Button
				type="link"
				onClick={() => swicthPanels()}
				style={{ fontSize: size ? size : "18px" }}
			>
				<VscSettingsGear />
			</Button>
		</Tooltip>
	) : null;
};

export default DashboardSwitchButton;
