import React, { FC, useEffect } from "react";
import { Form, Select } from "antd";

import useDepartments from "../hooks/useDepartments";

const SelectDepartment: FC<{}> = () => {
	const { departments, status, fetchDepartments } = useDepartments();
	useEffect(() => {
		if (!departments || !departments.length) fetchDepartments();
		// eslint-disable-next-line
	}, []);
	return (
		<Form.Item
			name="department_id"
			label="Department"
			rules={[
				{
					required: true,
					message: "Choose a department",
				},
			]}
		>
			<Select
				loading={status === "loading"}
				allowClear
				placeholder="Choose a department"
			>
				{departments.map((item, i) => (
					<Select.Option value={item.id} key={i}>
						{item.deptName}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default SelectDepartment;
