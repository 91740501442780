import React, { FC, useEffect } from "react";
import { Form, Select } from "antd";

import useGroups from "../hooks/useGroups";

interface SelectGroupProps {
	defaultValue?: number;
}

const SelectGroup: FC<SelectGroupProps> = ({ defaultValue }) => {
	const { groups, status, fetchGroups } = useGroups();
	useEffect(() => {
		if (!groups || !groups.length) fetchGroups();
		// eslint-disable-next-line
	}, []);
	return (
		<Form.Item
			name="group_id"
			label="Group"
			rules={[
				{
					required: true,
					message: "Choose a group",
				},
			]}
			initialValue={defaultValue}
		>
			<Select
				loading={status === "loading"}
				allowClear
				showSearch
				filterOption={(input, option) => {
					return (
						option?.children
							?.toLowerCase()
							?.indexOf(input.toLowerCase()) >= 0
					);
				}}
				placeholder="Choose a group"
			>
				{groups.map((item, i) => (
					<Select.Option value={item.id} key={i}>
						{item.groupName}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default SelectGroup;
