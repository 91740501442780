import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import useAuth from "common/hooks/useAuth";

import AdminView from "views/AdminView";
import HomeView from "views/HomeView";
import LoginView from "views/LoginView";
import BlockAdminView from "views/BlockAdminView";
import FallbackRoute from "common/components/FallbackRoute";
import useUI from "common/hooks/useUI";
import { useEffect } from "react";

const AppRoutes: React.FC = () => {
	const { auth } = useAuth();
	const { toggleSidebarCollapse, sidebar } = useUI();

	const location = useLocation();

	useEffect(() => {
		if (sidebar.collapsed) toggleSidebarCollapse();
		// eslint-disable-next-line
	}, [location.pathname]);

	return (
		<>
			{auth.isLogged ? (
				<Switch>
					<Route path="/" exact component={HomeView} />
					<Route path="/admin">
						{auth.role === "admin" ? (
							<AdminView />
						) : auth.role === "blockadmin" ? (
							<BlockAdminView />
						) : (
							<FallbackRoute adminPath />
						)}
					</Route>
					<Route path="/*" component={HomeView} />
				</Switch>
			) : (
				<Switch>
					<Route path="/" exact component={LoginView} />{" "}
					<Route path="/admin">
						<FallbackRoute adminPath />
					</Route>
					<Route path="*" component={FallbackRoute} />
				</Switch>
			)}
		</>
	);
};

export default AppRoutes;
