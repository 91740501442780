import React, { useEffect, useState } from "react";

import CPlayground from "common/components/CPlayground";
import useRequests from "../hooks/useRequests";
import useRooms from "features/Admin/Room/hooks/useRooms";
import useBlocks from "features/Admin/Blocks/hooks/useBlocks";

import { weekdays } from "moment";
import { parseTime } from "common/utils/time";
import { RequestResultItem } from "services/requests/requests.model";
import { Button, Descriptions, Divider, Popconfirm, Space, Spin } from "antd";

function findById<T>(id: number, records: T[]) {
	const rec = records.find((it: any) => it.id === id);
	if (rec) return rec;
	return null;
}

function PlaygroundWidget() {
	const { active, requestList, requesting, approveRequest, dismissRequest } =
		useRequests();
	const [reqData, setReqData] = useState<RequestResultItem | null>(null);

	const { rooms, fetchRooms } = useRooms();
	const { blocks, fetchBlocks } = useBlocks();

	useEffect(() => {
		if (!rooms.length) fetchRooms();
		if (!blocks.length) fetchBlocks();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!active) setReqData(null);
		else setReqData(requestList.find((item) => item.id === active) || null);
	}, [active, requestList]);

	const roomData = findById(reqData?.related_fields.room_id || 0, rooms);

	return (
		reqData && (
			<CPlayground>
				<h2 style={{ margin: "5px 0 35px" }}>Request details</h2>
				<Space direction="vertical" size={0}>
					<Descriptions
						labelStyle={{ fontWeight: 500 }}
						title={reqData.info.subject}
					>
						<Descriptions.Item label="Time">
							{parseTime(reqData.startTime)} -{" "}
							{parseTime(reqData.endTime)}
						</Descriptions.Item>
						<Descriptions.Item label="On">
							{reqData.temporaryOn
								? reqData.temporaryOn
								: weekdays(reqData.weekDay)}
						</Descriptions.Item>
						<Descriptions.Item label="Group">
							{reqData.info.group}
						</Descriptions.Item>
						<Descriptions.Item label="Instructor">
							{reqData.info.instructor}
						</Descriptions.Item>
						<Descriptions.Item label="In">
							Block{" "}
							{findById(
								roomData?.related_fields.block_id || 0,
								blocks
							)?.blockNumber || ""}
							, {roomData?.roomNumber || 0}
						</Descriptions.Item>
					</Descriptions>

					<Divider />

					<Space
						align="end"
						direction="vertical"
						style={{ width: "100%" }}
					>
						<Space direction="horizontal" size={20}>
							<Spin spinning={requesting === "loading"} />
							<Popconfirm
								title="Discard request?"
								okText="Discard"
								onConfirm={() => dismissRequest(reqData.id)}
								placement="left"
								cancelButtonProps={{
									type: "text",
									style: { color: "green", fontWeight: 500 },
								}}
								okButtonProps={{ danger: true }}
							>
								<Button type="text" danger>
									Discard
								</Button>
							</Popconfirm>
							<Button
								type="primary"
								onClick={() => {
									approveRequest(reqData.id);
								}}
							>
								Approve
							</Button>
						</Space>
					</Space>
				</Space>
			</CPlayground>
		)
	);
}

export default PlaygroundWidget;
