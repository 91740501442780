import React, { FC } from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

interface LoadingIndicatorProps {
	loading: boolean | Array<Status>;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ loading }) => {
	return (
		<Spin
			indicator={
				<Loading3QuartersOutlined
					style={{ fontSize: 24, color: "#228B22" }}
					spin
				/>
			}
			spinning={
				typeof loading === "boolean"
					? loading
					: loading.some((item: Status) => item === "loading")
			}
		/>
	);
};

export default LoadingIndicator;
