import React from "react";
import CPlayground from "common/components/CPlayground";
import UserRoutes from "routes/UserRoutes";

function Playground() {
	return (
		<CPlayground>
			<UserRoutes />
		</CPlayground>
	);
}

export default Playground;
