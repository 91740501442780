import React, { FC } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Form, Input, ModalProps } from "antd";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { onInstructorCreate } from "services/instructor/instructor.service";
import { instructorActions } from "redux/reducers/instructor.redux";

import SelectDepartment from "features/Admin/Departments/widgets/SelectDepartment";
import {
	InstructorData,
	InstructorModel,
} from "services/instructor/instructor.model";

export interface InstructorCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const InstructorCreateModal: FC<InstructorCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		InstructorData,
		InstructorModel
	>({
		serviceFunction: onInstructorCreate,
		action: instructorActions.appendInstructors,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Register instructor"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					createRecord(values as InstructorData);
				}}
				layout="vertical"
			>
				<Form.Item
					name="instructorName"
					label="Name"
					rules={[
						{
							required: true,
							message: "Instructor's name is required.",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>

				<SelectDepartment />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default InstructorCreateModal;
