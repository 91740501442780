import moment from "moment";
import { useSelector } from "react-redux";
import {
	createRoutineActions,
	RoutineTemporaryState,
	SlotInfo,
} from "redux/reducers/routine.create.redux";
import { RootState, useAppDispatch } from "redux/store.redux";

const useCreateRoutine = () => {
	const { context, showModal } = useSelector(
		(state: RootState) => state.createRoutine
	);

	const dispatch = useAppDispatch();

	const createRoutine = (slot: SlotInfo, group?: number) => {
		const date = moment(slot.start);
		dispatch(
			createRoutineActions.initCreate({
				slot,
				group,
				temporary: {
					oneOff: false,
					date,
				},
			})
		);
	};

	const setTempData = (payload: RoutineTemporaryState) =>
		dispatch(createRoutineActions.setTemporary(payload));

	const cancelCreateRoutine = () => {
		dispatch(createRoutineActions.resetCreate());
	};

	return {
		context,
		showModal,
		setTempData,
		createRoutine,
		cancelCreateRoutine,
	};
};

export default useCreateRoutine;
