import React from "react";

import CSidebar from "common/components/CSidebar";
import HeaderSidebarWidget from "./Header.sidebar.widget";
import ListItemSidebarWidget from "./ListItem.sidebar.widget";
import useRequests from "features/BlockAdmin/hooks/useRequests";

import { List } from "antd";
import { AdminPageOptionList } from "features/Admin/admin.style";

function SidebarWidget() {
	const { status, requestList, active, changeActive, fetchRequests } =
		useRequests();

	return (
		<CSidebar>
			<AdminPageOptionList>
				<List
					itemLayout="horizontal"
					dataSource={requestList}
					renderItem={(item) => (
						<ListItemSidebarWidget
							active={active}
							item={item}
							onItemClick={(id) => changeActive(id)}
						/>
					)}
					header={
						<HeaderSidebarWidget
							status={status}
							onRefresh={() => fetchRequests()}
							count={requestList.length}
						/>
					}
					loading={status === "loading"}
					rowKey={(item) => `list-row-item-${item.id}`}
				/>
			</AdminPageOptionList>
		</CSidebar>
	);
}

export default SidebarWidget;
