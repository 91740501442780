import React from "react";
import styled from "styled-components";
import { Popconfirm, Popover } from "antd";
import { VscSignOut } from "react-icons/vsc";

import useAuth from "common/hooks/useAuth";

const LogOutButtonWrapper = styled.button`
	border: none;
	background-color: rgba(12, 26, 50, 0);
	transform: translate(-2px, 2px);
`;

interface LProps {
	text?: boolean;
}

function LogoutButton(props: LProps) {
	const { logUserOut } = useAuth();
	return (
		<LogOutButtonWrapper>
			<Popconfirm
				title="Are you sure you want to log out?"
				onConfirm={logUserOut}
				okText="Logout"
			>
				<Popover content="Logout">
					{props.text ? "Log Out" : <VscSignOut size={18} />}
				</Popover>
			</Popconfirm>
		</LogOutButtonWrapper>
	);
}

export default LogoutButton;
