import remoteApi from "common/remote/api";
import { GroupData, GroupModel } from "./groups.model";

export const onGroupGet = (): Promise<GroupModel[]> => remoteApi.get("/group");

export const onGroupCreate = (payload: GroupData): Promise<GroupModel> =>
	remoteApi.post("/group", { ...payload });

export const onGroupUpdate = (
	id: number,
	payload: GroupData
): Promise<GroupModel> => remoteApi.patch(`/group/${id}`, payload);

export const onGroupDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/group/${id}`);
