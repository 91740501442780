import uiSlice from "./ui.redux";
import authSlice from "./auth.redux";
import roomSlice from "./rooms.redux";
import userSlice from "./users.redux";
import groupSlice from "./group.redux";
import blockSlice from "./blocks.redux";
import subjectSlice from "./subject.redux";
import routineSlice from "./routines.redux";
import instructorSlice from "./instructor.redux";
import departmentSlice from "./departments.redux";
import createRoutineSlice from "./routine.create.redux";
import recordDeleteSlice from "./record.delete.redux";
import routineViewSlice from "./routine.view.redux";
import searchSlice from "./search.redux";
import requestSlice from "./requests.redux";

export const reducers = {
	auth: authSlice.reducer,
	blocks: blockSlice.reducer,
	rooms: roomSlice.reducer,
	users: userSlice.reducer,
	routines: routineSlice.reducer,
	departments: departmentSlice.reducer,
	groups: groupSlice.reducer,
	subjects: subjectSlice.reducer,
	instructors: instructorSlice.reducer,
	createRoutine: createRoutineSlice.reducer,
	routineView: routineViewSlice.reducer,
	recordDelete: recordDeleteSlice.reducer,
	request: requestSlice.reducer,
	ui: uiSlice.reducer,
	search: searchSlice.reducer,
};
