import React from "react";
import { TransitionedRoutePage } from "features/Admin/admin.style";
import SearchRoomTable from "./widgets/SearchRoomTable";

function Search() {
	return (
		<TransitionedRoutePage>
			<SearchRoomTable />
		</TransitionedRoutePage>
	);
}

export default Search;
