import React, { FC, useContext, useEffect, useState } from "react";
import moment from "moment";
import useRoutines from "../hooks/useRoutines";
import useEditRecord from "common/hooks/useEditRecord";
import CWeekDaySelect from "common/widgets/CWeekDaySelect";
import DeleteModal from "features/Admin/widgets/DeleteModal";
import CTimeInputSelect from "common/widgets/CTimeInputSelect";

import {
	onRoutineCancel,
	onRoutineDelete,
	onRoutineUpdate,
} from "services/routine/routine.service";
import { Meridian } from "./RoutineCreateModal";
import { Button, Col, message, ModalProps, notification, Row } from "antd";
import { routineActions } from "redux/reducers/routines.redux";
import { editRoutineContext } from "../contexts/EditRoutineContext";
import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { RoutineData, RoutineModel } from "services/routine/routine.model";

interface RoutineEditModalProps extends ModalProps {
	closeModal: () => void;
}

const RoutineEditModal: FC<RoutineEditModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { slot: record } = useContext(editRoutineContext);
	const { findbyId, updateRoutine } = useRoutines();
	const [relatedRecord, setRelatedRecord] = useState<RoutineModel | null>(
		null
	);
	const {
		visible,
		closeModal: closeDeleteModal,
		name,
		onFinish,
		openModal: openDeleteModal,
	} = useDeleteModal(onRoutineDelete, routineActions.deleteRoutine, () => {
		closeModal();
	});

	const meridian = moment(record?.start).format("a") as Meridian;
	const startTime =
		parseInt(moment(record?.start).format("hhmm")) +
		(meridian === "pm" ? 1200 : 0);
	const endTime =
		parseInt(moment(record?.end).format("hhmm")) +
		(meridian === "pm" ? 1200 : 0);

	const { editRecord, form, error, formStatus } = useEditRecord<
		RoutineData,
		RoutineModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onRoutineUpdate,
		action: routineActions.updateRoutine,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const cancelRoutineThisDay = async () => {
		try {
			if (record) {
				const resp = await onRoutineCancel(record.id, record.start);

				updateRoutine(resp.id, resp);
				notification.success({ message: "Cancellation successful." });
				closeModal();
			}
		} catch (ex) {
			console.log({ ex });
			message.error("Routine cancellation failed. Try again!!");
		}
	};
	useEffect(() => {
		if (record) {
			const rc = findbyId(record.id);
			if (rc) setRelatedRecord(rc);
		}
	}, [record, findbyId]);

	return (
		record && (
			<>
				<StyledModal
					{...otherProps}
					title="Edit Routine"
					onCancel={closeModal}
					visible
					centered
					width={400}
					onOk={onOk}
					footer={null}
				>
					<h2>{relatedRecord?.info.subject}</h2>
					<p>
						{relatedRecord?.info.group} -{" "}
						{relatedRecord?.info.instructor}
					</p>

					<StyledForm
						form={form}
						onFinish={(values) => {
							editRecord(values as RoutineData);
						}}
						preserve={false}
						layout="vertical"
					>
						<Row gutter={5}>
							<Col span={12}>
								<CWeekDaySelect
									day={record?.start || new Date()}
								/>
							</Col>
							<Col span={6}>
								<CTimeInputSelect
									name="startTime"
									label="Start Time"
									defaultValue={startTime}
								/>
							</Col>
							<Col span={6}>
								<CTimeInputSelect
									name="endTime"
									label="- &nbsp; &nbsp; End Time"
									defaultValue={endTime}
								/>
							</Col>
						</Row>

						{error && <p className="error">{error}</p>}
						<StyledForm.Item>
							<Button
								block
								type="primary"
								htmlType="submit"
								loading={formStatus === "loading"}
							>
								{formStatus === "loading"
									? "Updating..."
									: "Update"}
							</Button>

							<Button
								block
								style={{ marginTop: 20 }}
								type="ghost"
								onClick={() => {
									cancelRoutineThisDay();
								}}
							>
								Cancel on this day
							</Button>
							<Button
								style={{ marginTop: 20 }}
								danger
								type="text"
								block
								onClick={() => {
									openDeleteModal(record, `routine slot`);
								}}
							>
								Delete
							</Button>
						</StyledForm.Item>
					</StyledForm>
				</StyledModal>

				<DeleteModal
					{...{
						visible,
						name,
						onFinish,
						closeModal: closeDeleteModal,
					}}
				/>
			</>
		)
	);
};

export default RoutineEditModal;
