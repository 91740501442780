import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Moment } from "moment";

export interface SlotInfo {
	id: number;
	start: Date;
	end: Date;
	slots: Date[];
	action: "select" | "click" | "doubleClick";
}
export interface RoutineTemporaryState {
	oneOff: boolean;
	date: Moment;
}
export interface RoutineCreateData {
	slot: SlotInfo | null;
	temporary: RoutineTemporaryState;
	group?: number;
}
export interface rCreateRoutine {
	showModal: boolean;
	context: RoutineCreateData | null;
}

const initState: rCreateRoutine = {
	showModal: false,
	context: null,
};

// actions
const initCreate: CaseReducer<
	rCreateRoutine,
	PayloadAction<RoutineCreateData>
> = (state, action) => ({
	...state,
	showModal: true,
	context: action.payload,
});
const closeCreate: CaseReducer<rCreateRoutine> = (state) => ({
	...state,
	showModal: false,
});
const setTemporary: CaseReducer<
	rCreateRoutine,
	PayloadAction<RoutineTemporaryState>
> = (state, action) => ({
	...state,
	context: {
		...state.context,
		slot: state.context?.slot || null,
		temporary: action.payload,
	},
});

const resetCreate = (): rCreateRoutine => initState;

const createRoutineSlice = createSlice({
	name: "create-routine",
	initialState: initState,
	reducers: {
		initCreate,
		closeCreate,
		resetCreate,
		setTemporary,
	},
});

export const createRoutineActions = createRoutineSlice.actions;
export default createRoutineSlice;
