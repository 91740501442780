import { Button } from "antd";
import LoadingIndicator from "common/components/LoadingIndicator";
import React, { useEffect, useState } from "react";
import { TransitionedRoutePage } from "../admin.style";

import AdminPageHeader from "../widgets/PageHeader";
import useUsers from "./hooks/useUsers";
import UserCreateModal from "./widgets/UserCreateModal";
import AdminUserTable from "./widgets/UserTable";

function UserAdmin() {
	const { users, error, status, fetchUsers } = useUsers();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	useEffect(() => {
		if (!users.length) fetchUsers();

		// eslint-disable-next-line
	}, []);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Users"
				indicator={<LoadingIndicator loading={[status]} />}
			>
				<Button onClick={() => setShowCreateModal(!!!showCreateModal)}>
					Add New
				</Button>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<AdminUserTable isLoading={status === "loading"} users={users} />
			<UserCreateModal
				visible={!!showCreateModal}
				closeModal={() => setShowCreateModal(false)}
			/>
		</TransitionedRoutePage>
	);
}

export default UserAdmin;
