import React from "react";
import adminAvatar from "common/assets/icons/admin.png";
import userAvatar from "common/assets/icons/user.png";
import LogoutButton from "common/widgets/LogoutButton";
import useAuth from "common/hooks/useAuth";
import styled from "styled-components";

const StyledSidebarFooter = styled.footer`
	flex: 0 0 40px;
	padding: 5px 10px;
	z-index: 9;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	box-shadow: 0px -1px 5px rgba(12, 26, 50, 0.1);

	.info {
		display: flex;
		flex-direction: row;
		align-items: center;

		.avatar {
			height: 30px;
			width: 30px;
			border-radius: 50%;
			border: 0.5px solid lightgrey;
			margin-right: 10px;

			img {
				height: 100%;
				width: 100%;
			}
		}

		h3 {
			font-weight: 600;
			font-size: var(--fs-md);

			color: var(--blue);
			margin: 0;
		}
	}
	.controls {
		height: 25px;
		width: 25px;
		border-radius: 50%;
		margin-right: 5px;

		overflow: hidden;
	}
`;

function CSidebarFooter() {
	const { auth } = useAuth();

	return (
		<StyledSidebarFooter>
			<div className="info">
				<div className="avatar">
					<img
						src={auth.role === "admin" ? adminAvatar : userAvatar}
						alt="avatar user or admin"
					/>
				</div>
				<h3 className="email">@{auth.username}</h3>
			</div>
			<div className="controls">
				<LogoutButton />
			</div>
		</StyledSidebarFooter>
	);
}

export default CSidebarFooter;
