import React, { FC } from "react";
import {
	Button,
	Checkbox,
	Form,
	InputNumber,
	Modal,
	ModalProps,
	Select,
} from "antd";
import { RoomData, RoomModel } from "services/room/room.model";
import { onRoomUpdate } from "services/room/room.service";
import { roomActions } from "redux/reducers/rooms.redux";
import useEditRecord from "common/hooks/useEditRecord";
import { StyledForm } from "features/Admin/admin.style";
import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";

interface RoomEditModalProps extends ModalProps {
	record: RoomModel | null;
	closeModal: () => void;
}

const RoomEditModal: FC<RoomEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		RoomData,
		RoomModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onRoomUpdate,
		action: roomActions.updateRoom,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit room"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as RoomData);
				}}
				preserve={false}
				layout="vertical"
			>
				<SelectBlock />
				<Form.Item
					name="roomNumber"
					initialValue={record.roomNumber}
					label="Room Number"
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="roomCapacity"
					initialValue={record.roomCapacity}
					label="Capacity"
				>
					<InputNumber />
				</Form.Item>
				<Form.Item name="roomType" label="Room Type">
					<Select
						allowClear
						defaultValue={record.roomType}
						placeholder="Choose a type"
					>
						{["classroom", "lab"].map((item, i) => (
							<Select.Option value={item} key={i}>
								{item}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name="projectorAvailable" valuePropName="checked">
					<Checkbox defaultChecked={!!record?.projectorAvailable}>
						Room has a projector
					</Checkbox>
				</Form.Item>

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default RoomEditModal;
