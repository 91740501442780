import React, { FC } from "react";
import { Button, Form, Input, Modal, ModalProps, Select } from "antd";
import { UserData, UserModel } from "services/user/user.model";
import { userRoles } from "./UserTable";
import { StyledForm } from "features/Admin/admin.style";
import { onUserUpdate } from "services/user/user.service";
import { userActions } from "redux/reducers/users.redux";
import useEditRecord from "common/hooks/useEditRecord";

interface UserEditModalProps extends ModalProps {
	record: UserModel | null;
	closeModal: () => void;
}

const UserEditModal: FC<UserEditModalProps> = ({
	record,
	closeModal,
	...otherProps
}) => {
	const { editRecord, form, error, formStatus } = useEditRecord<
		UserData,
		UserModel
	>({
		recordId: record?.id || 0,
		serviceFunction: onUserUpdate,
		action: userActions.updateUser,
		onRecordEdited: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};

	return record ? (
		<Modal
			{...otherProps}
			title="Edit User"
			visible
			centered
			onCancel={onCancel}
			width={400}
			onOk={onOk}
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					editRecord(values as UserModel);
				}}
				preserve={false}
				layout="vertical"
			>
				<Form.Item name="password" label="Password">
					<Input.Password />
				</Form.Item>

				<Form.Item name="role_id" label="Role">
					<Select
						allowClear
						defaultValue={record.related_fields.role_id}
					>
						{userRoles.map((item, i) => (
							<Select.Option key={i} value={item.id}>
								{item.value}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Updating..." : "Update"}
					</Button>
				</Form.Item>
			</StyledForm>
		</Modal>
	) : null;
};

export default UserEditModal;
