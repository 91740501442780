import React from "react";
import CPlayground from "common/components/CPlayground";
import AdminRoutes from "routes/AdminRoutes";

function AdminPlayground() {
	return (
		<CPlayground>
			<AdminRoutes />
		</CPlayground>
	);
}

export default AdminPlayground;
