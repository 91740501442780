import { Button } from "antd";
import LoadingIndicator from "common/components/LoadingIndicator";
import React, { useEffect, useState } from "react";
import { TransitionedRoutePage } from "../admin.style";

import AdminPageHeader from "../widgets/PageHeader";
import useBlocks from "./hooks/useBlocks";
import BlockCreateModal from "./widgets/BlockCreateModal";
import AdminBlockTable from "./widgets/BlockTable";

function BlockAdmin() {
	const { blocks, error, status, fetchBlocks } = useBlocks();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

	useEffect(() => {
		if (!blocks.length) fetchBlocks();

		// eslint-disable-next-line
	}, []);

	return (
		<TransitionedRoutePage>
			<AdminPageHeader
				page="Blocks"
				indicator={<LoadingIndicator loading={[status]} />}
			>
				<Button onClick={() => setShowCreateModal(!!!showCreateModal)}>
					Add New
				</Button>
			</AdminPageHeader>
			{error && <p className="error">{error}</p>}
			<AdminBlockTable isLoading={status === "loading"} blocks={blocks} />
			<BlockCreateModal
				visible={!!showCreateModal}
				closeModal={() => setShowCreateModal(false)}
			/>
		</TransitionedRoutePage>
	);
}

export default BlockAdmin;
