import React, { FC } from "react";

import { StyledForm, StyledModal } from "features/Admin/admin.style";
import { Button, Form, Input, ModalProps } from "antd";

import useCreateNewRecord from "common/hooks/useCreateNewRecord";
import { departmentActions } from "redux/reducers/departments.redux";
import { onDepartmentCreate } from "services/departments/department.service";

import {
	DepartmentData,
	DepartmentModel,
} from "services/departments/department.model";
import SelectBlock from "features/Admin/Blocks/widgets/SelectBlock";

export interface DepartmentCreateModalProps extends ModalProps {
	closeModal: () => void;
}

const DepartmentCreateModal: FC<DepartmentCreateModalProps> = ({
	closeModal,
	...otherProps
}) => {
	const { form, formStatus, createRecord, error } = useCreateNewRecord<
		DepartmentData,
		DepartmentModel
	>({
		serviceFunction: onDepartmentCreate,
		action: departmentActions.appendDepartments,
		onRecordCreated: () => {
			closeModal();
		},
	});

	const onOk = () => {
		form.submit();
	};

	const onCancel = (e: any) => {
		closeModal();
	};
	return (
		<StyledModal
			{...otherProps}
			onCancel={onCancel}
			title="Create department"
			width={400}
			onOk={onOk}
			centered
			footer={null}
		>
			<StyledForm
				form={form}
				onFinish={(values) => {
					createRecord(values as DepartmentData);
				}}
				layout="vertical"
			>
				<Form.Item
					name="deptName"
					label="Name"
					rules={[
						{
							required: true,
							message: "Department name is required.",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>

				<SelectBlock />

				{error && <p className="error">{error}</p>}
				<Form.Item>
					<Button
						style={{ width: "100%" }}
						type="primary"
						htmlType="submit"
						loading={formStatus === "loading"}
					>
						{formStatus === "loading" ? "Creating" : "Create"}
					</Button>
				</Form.Item>
			</StyledForm>
		</StyledModal>
	);
};

export default DepartmentCreateModal;
