import PlaygroundWidget from "features/BlockAdmin/widgets/Playground.widget";
import SidebarWidget from "features/BlockAdmin/widgets/Sidebar.widget";
import React from "react";

import { BlockAdminViewWrapper } from "./blockadminview.style";

function BlockAdminView() {
	return (
		<BlockAdminViewWrapper>
			<SidebarWidget />
			<PlaygroundWidget />
		</BlockAdminViewWrapper>
	);
}

export default BlockAdminView;
