import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { BlockModel } from "services/block/block.model";

interface rBlock {
	blocks: BlockModel[];
	error?: string;
	status: Status;
}

const initState: rBlock = {
	blocks: [],
	error: "",
	status: "idle",
};

// actions
const appendBlocks: CaseReducer<rBlock, PayloadAction<BlockModel[]>> = (
	state,
	action
): rBlock => ({
	status: "loaded",
	error: "",
	blocks: [...state.blocks, ...action.payload],
});
const updateBlock: CaseReducer<
	rBlock,
	PayloadAction<{ id: number; data: BlockModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		blocks: state.blocks.map((block) => {
			if (block.id !== id) return block;
			return { ...data, id };
		}),
	};
};
const addBlocks: CaseReducer<rBlock, PayloadAction<BlockModel[]>> = (
	_,
	action
): rBlock => ({
	status: "loaded",
	error: "",
	blocks: action.payload,
});

const deleteBlock: CaseReducer<rBlock, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	blocks: state.blocks.filter((block) => block.id !== action.payload.id),
});
const resetBlocks: CaseReducer = (): rBlock => initState;
const loading: CaseReducer<rBlock> = (state): rBlock => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rBlock, PayloadAction<string>> = (
	state,
	action
): rBlock => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const blockSlice = createSlice({
	name: "block",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addBlocks,
		appendBlocks,
		updateBlock,
		resetBlocks,
		deleteBlock,
	},
});

// exports
export const blockActions = blockSlice.actions;
export default blockSlice;
