import remoteApi from "common/remote/api";
import moment from "moment";
import { RoutineData, RoutineModel } from "./routine.model";

export const onRoutinesGet = (): Promise<RoutineModel[]> =>
	remoteApi.get("/routineSlot");

export const onRoutinesCreate = (payload: RoutineData): Promise<RoutineModel> =>
	remoteApi.post("/routineSlot", payload);

export const onRoutineGetByRoom = (roomId: number): Promise<RoutineModel[]> =>
	remoteApi.get(`/routineSlot/by_room/${roomId}`);

export const onRoutineUpdate = (
	id: number,
	payload: RoutineData
): Promise<RoutineModel> => remoteApi.patch(`/routineSlot/${id}`, payload);

export const onRoutineDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/routineSlot/${id}`);

export const onGroupWiseCSVFetch = (group_id: number): Promise<void> =>
	remoteApi.get(`/routineSlot/by_group_excel/${group_id}`);

export const onClassRequest = (payload: any): Promise<RoutineModel> =>
	remoteApi.post(`/routineSlot/request_class`, { ...payload });

export const onRoutineCancel = (
	routineId: number,
	cancelOn: Date
): Promise<RoutineModel> =>
	remoteApi.post("/routineSlot/cancel_class/" + routineId, {
		routineSlot_id: routineId,
		date: moment(cancelOn).format("YYYY-MM-DD"),
	});
