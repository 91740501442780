import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { SubjectModel } from "services/subject/subject.model";

interface rSubject {
	subjects: SubjectModel[];
	error?: string;
	status: Status;
}

const initState: rSubject = {
	subjects: [],
	error: "",
	status: "idle",
};

// actions
const appendSubjects: CaseReducer<rSubject, PayloadAction<SubjectModel[]>> = (
	state,
	action
): rSubject => ({
	status: "loaded",
	error: "",
	subjects: [...state.subjects, ...action.payload],
});

const updateSubject: CaseReducer<
	rSubject,
	PayloadAction<{ id: number; data: SubjectModel }>
> = (state, action) => {
	const { id, data } = action.payload;
	return {
		...state,
		subjects: state.subjects.map((sub) => {
			if (sub.id !== id) return sub;
			return { ...data, id };
		}),
	};
};
const addSubjects: CaseReducer<rSubject, PayloadAction<SubjectModel[]>> = (
	_,
	action
): rSubject => ({
	status: "loaded",
	error: "",
	subjects: action.payload,
});

const deleteSubject: CaseReducer<rSubject, PayloadAction<{ id: number }>> = (
	state,
	action
) => ({
	...state,
	subjects: state.subjects.filter((sub) => sub.id !== action.payload.id),
});
const resetSubjects: CaseReducer = (): rSubject => initState;
const loading: CaseReducer<rSubject> = (state): rSubject => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<rSubject, PayloadAction<string>> = (
	state,
	action
): rSubject => ({
	...state,
	status: "hasError",
	error: action.payload,
});

// slice
const subjectSlice = createSlice({
	name: "subject",
	initialState: initState,
	reducers: {
		loading,
		failed,
		addSubjects,
		appendSubjects,
		updateSubject,
		resetSubjects,
		deleteSubject,
	},
});

// exports
export const subjectActions = subjectSlice.actions;
export default subjectSlice;
