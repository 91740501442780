import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Form, message, notification } from "antd";
import { useState } from "react";
import { useAppDispatch } from "redux/store.redux";

interface useEditRecordProps<T, M> {
	recordId: number;
	serviceFunction: (id: number, payload: T) => Promise<M>;
	action: ActionCreatorWithPayload<{ data: M; id: number }>;
	onRecordEdited?: (record: M) => void;
}

function useEditRecord<T, M>({
	recordId,
	action,
	serviceFunction,
	onRecordEdited,
}: useEditRecordProps<T, M>) {
	const [formState, setFormState] = useState<{
		status: Status;
		error?: string;
	}>({ status: "idle", error: "" });

	const [form] = Form.useForm<T>();
	const dispatch = useAppDispatch();

	const editRecord = async (values: T) => {
		try {
			setFormState({ status: "loading" });

			const res = await serviceFunction(recordId, values);

			notification.success({
				message: "Record edited successfully.",
			});

			dispatch(action({ data: res, id: recordId }));
			form.resetFields();

			setFormState({ status: "loaded" });
			onRecordEdited && onRecordEdited(res);
		} catch (ex) {
			setFormState({ status: "hasError", error: ex.message });
			message.error("There was an error while creating new record.");
		}
	};

	return {
		form,
		editRecord,
		formStatus: formState.status,
		error: formState.error,
	};
}

export default useEditRecord;
