import React from "react";
import CSidebar from "common/components/CSidebar";
import { NavLink } from "react-router-dom";
import { AdminPageOptionList } from "../admin.style";

function AdminSidebar() {
	return (
		<CSidebar>
			<AdminPageOptionList>
				<li>
					<NavLink to="/admin/routines">Routines</NavLink>
				</li>
				<li>
					<NavLink to="/admin/users">Users</NavLink>
				</li>
				<li>
					<NavLink to="/admin/blocks">Blocks</NavLink>
				</li>
				<li>
					<NavLink to="/admin/rooms">Rooms</NavLink>
				</li>
				<li>
					<NavLink to="/admin/departments">Departments</NavLink>
				</li>
				<li>
					<NavLink to="/admin/instructors">Instructors</NavLink>
				</li>
				<li>
					<NavLink to="/admin/groups">Groups</NavLink>
				</li>
				<li>
					<NavLink to="/admin/subjects">Subjects</NavLink>
				</li>
			</AdminPageOptionList>
		</CSidebar>
	);
}

export default AdminSidebar;
