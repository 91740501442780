import React, { FC } from "react";
import { RoomModel } from "services/room/room.model";

import CDataTable from "common/components/CDataTable";
import { IDataTableColumn } from "react-data-table-component";
import useSearch from "../hooks/useSearch";
import useRoutineView from "features/Home/Routines/hooks/useRoutineView";
import { useHistory } from "react-router-dom";

const tableModel = (): IDataTableColumn<RoomModel>[] => [
	{
		name: "Room",
		selector: "roomNumber",
		cell: (room) => <span>{room.roomNumber}</span>,
		sortable: true,
		maxWidth: "70px",
	},
	{
		name: "Block",
		selector: "related_fields.block_id",
		cell: (room) => <span>{room.related_fields.block_id}</span>,
		sortable: true,
	},
	{
		name: "Capacity",
		selector: "roomCapacity",
		cell: (room) => <span>{room.roomCapacity}</span>,
		sortable: true,
		center: true,
		maxWidth: "70px",
	},
	{
		name: "Type",
		selector: "roomType",
		cell: (room) => <span>{room.roomType}</span>,
	},
];

const SearchRoomTable: FC<{}> = () => {
	const { responses, status } = useSearch();

	const {changeRoom} = useRoutineView()
	const history = useHistory()

	return (
		<>
			<CDataTable
				columns={tableModel()}
				data={responses}
				isLoading={status === "loading"}
				onRowClicked={(row) => {
					const room = row as RoomModel
					changeRoom(room.id)
					history.push('/routines')
				}}
			/>
		</>
	);
};

export default SearchRoomTable;
