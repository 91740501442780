import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestResultItem } from "services/requests/requests.model";

interface rRequest {
	lists: Array<RequestResultItem>;
	active: number | null;
	error?: string;
	status: Status;
	requesting: Status;
}

const initState: rRequest = {
	lists: [],
	active: null,
	error: "",
	status: "idle",
	requesting: "idle",
};

const resetRequests: CaseReducer<rRequest> = () => initState;
const addRequests: CaseReducer<rRequest, PayloadAction<RequestResultItem[]>> = (
	_,
	action
) => ({
	lists: action.payload,
	active: null,
	status: "loaded",
	error: "",
	requesting: "idle",
});
const deleteRequest: CaseReducer<rRequest, PayloadAction<number>> = (
	state,
	action
) => ({
	...state,
	lists: state.lists.filter((item) => item.id !== action.payload),
	active: state.active === action.payload ? null : state.active,
});
const changeActive: CaseReducer<rRequest, PayloadAction<number>> = (
	state,
	action
) => {
	const active = action.payload;
	if (active < 0 && active >= state.lists.length) return state;
	return { ...state, active, requesting: "idle" };
};
const loading: CaseReducer<rRequest> = (state) => ({
	...state,
	status: "loading",
});
const failed: CaseReducer<rRequest, PayloadAction<string>> = (
	state,
	action
) => ({
	...state,
	status: "hasError",
	error: action.payload,
});
const requesting: CaseReducer<rRequest> = (state) => ({
	...state,
	requesting: "loading",
});

const requestComplete: CaseReducer<rRequest> = (state) => ({
	...state,
	requesting: "loaded",
});

const requestFailed: CaseReducer<rRequest> = (state) => ({
	...state,
	requesting: "hasError",
});
const requestSlice = createSlice({
	name: "request",
	initialState: initState,
	reducers: {
		deleteRequest,
		addRequests,
		changeActive,
		resetRequests,
		loading,
		failed,
		requesting,
		requestComplete,
		requestFailed,
	},
});

// exports
export const requestActions = requestSlice.actions;
export default requestSlice;
