import React from "react";

import logo from "common/assets/ku-logo.png";
import styled from "styled-components";
import DashboardSwitchButton from "./DashboardSwitchButton";

const StyledSidebarHeader = styled.header`
	flex: 0 0 60px;

	z-index: 9;

	padding: 5px 10px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.main {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	color: var(--blue);

	font-family: var(--ff);

	box-shadow: 0px 1px 9px rgba(12, 26, 50, 0.2);

	.logo {
		margin-right: 10px;
	}

	h3 {
		margin: 0;
		line-height: 1.3;
		font-size: var(--fs);
	}
	p {
		margin: 0;
		line-height: 1.5;
		font-size: var(--fs-sm);
	}
`;

function CSidebarHeader() {
	return (
		<StyledSidebarHeader>
			<div className="main">
				<div className="logo">
					<img src={logo} alt="ku-logo" width={50} height={50} />
				</div>
				<div className="info">
					<h3>RMS</h3>
					<p>Kathmandu University, Dhulikhel</p>
				</div>
			</div>
			<div className="admin-controls" style={{ justifySelf: "flex-end" }}>
				<DashboardSwitchButton />
			</div>
		</StyledSidebarHeader>
	);
}

export default CSidebarHeader;
