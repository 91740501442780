import remoteApi from "common/remote/api";
import { SubjectData, SubjectModel } from "./subject.model";

export const onSubjectGet = (): Promise<SubjectModel[]> =>
	remoteApi.get("/subject");

export const onSubjectCreate = (payload: SubjectData): Promise<SubjectModel> =>
	remoteApi.post("/subject", { ...payload });

export const onSubjectUpdate = (
	id: number,
	payload: SubjectData
): Promise<SubjectModel> => remoteApi.patch(`/subject/${id}`, payload);

export const onSubjectDelete = (id: number): Promise<string> =>
	remoteApi.delete(`/subject/${id}`);
