import { message as msg } from "antd";
import { useSelector } from "react-redux";
import { subjectActions } from "redux/reducers/subject.redux";
import { RootState, useAppDispatch } from "redux/store.redux";
import { onSubjectGet } from "services/subject/subject.service";

const useSubjects = () => {
	const { subjects, error, status } = useSelector(
		(state: RootState) => state.subjects
	);
	const dispatch = useAppDispatch();

	const fetchSubjects = async () => {
		try {
			dispatch(subjectActions.loading());
			const departsFromApi = await onSubjectGet();
			dispatch(subjectActions.addSubjects(departsFromApi));
		} catch (ex) {
			const {
				message = "Could not fetch department data at the moment.",
			} = ex;
			dispatch(subjectActions.failed(message));
			msg.error(message);
		}
	};

	return { subjects, error, status, fetchSubjects };
};
export default useSubjects;
