import { useState } from "react";

interface EditModalProps<T> {
	record: T | null;
	visible: boolean;
}

export interface OpenEditModalFunction<T> {
	(record: T | undefined): void;
}

interface EditModalHookReturnType<T> {
	editModal: EditModalProps<T>;
	openEditModal: OpenEditModalFunction<T>;
	closeEditModal: () => void;
}

function useEditModal<Type>(): EditModalHookReturnType<Type> {
	const [editModal, setEditModal] = useState<EditModalProps<Type>>({
		visible: false,
		record: null,
	});

	const openEditModal: OpenEditModalFunction<Type> = (record) => {
		if (record)
			setEditModal({
				record,
				visible: true,
			});
	};
	const closeEditModal = () => {
		setEditModal({
			record: null,
			visible: false,
		});
	};

	return { editModal, openEditModal, closeEditModal };
}

export default useEditModal;
