import React, { FC } from "react";

import { MoreOutlined } from "@ant-design/icons";
import CDataTable, { TableModelFunction } from "common/components/CDataTable";
import useEditModal from "common/hooks/useEditModal";
import TableActionDropdown from "features/Admin/widgets/TableActionDropdown";
import CDropdown from "common/components/CDropdown";
import InstructorEditModal from "./InstructorEditModal";
import { InstructorModel } from "services/instructor/instructor.model";
import useInstructor from "../hooks/useInstructor";
import { useDeleteModal } from "features/Admin/hooks/useDeleteModal";
import { onInstructorDelete } from "services/instructor/instructor.service";
import { instructorActions } from "redux/reducers/instructor.redux";
import DeleteModal from "features/Admin/widgets/DeleteModal";

const tableModel: TableModelFunction<InstructorModel> = (
	openEditor,
	onDelete
) => [
	{
		name: "Name",
		selector: "groupName",
		cell: (instructor) => <span>{instructor.instructorName}</span>,
		sortable: true,
	},
	{
		name: "Actions",
		cell: (ins) => (
			<CDropdown
				overlay={
					<TableActionDropdown
						onEditClick={() => {
							openEditor(ins);
						}}
						onDeleteClick={() => {
							onDelete(ins);
						}}
					/>
				}
				trigger={["click"]}
			>
				<span className="actions">
					<MoreOutlined />
				</span>
			</CDropdown>
		),
		right: true,
		maxWidth: "70px",
	},
];

interface AdminInstructorTableProps {
	instructors: InstructorModel[];
	isLoading: boolean;
}

const AdminInstructorTable: FC<AdminInstructorTableProps> = ({
	instructors,
	isLoading,
}) => {
	const { closeEditModal, openEditModal, editModal } =
		useEditModal<InstructorModel>();

	const { instructors: instrs } = useInstructor();
	const { visible, openModal, name, onFinish, closeModal } = useDeleteModal(
		onInstructorDelete,
		instructorActions.deleteInstructor
	);

	return (
		<>
			<CDataTable
				columns={
					tableModel(
						(instructor) => {
							const _instr = instrs.find(
								(inst) => inst.id === instructor.id
							);
							openEditModal(_instr);
						},
						(ins) => {
							openModal(
								ins,
								`instructor record: ${ins.instructorName}`
							);
						}
					) as any
				}
				data={instructors}
				isLoading={isLoading}
			/>
			<InstructorEditModal {...editModal} closeModal={closeEditModal} />
			<DeleteModal {...{ onFinish, closeModal, visible, name }} />
		</>
	);
};

export default AdminInstructorTable;
