import React from "react";
import { Empty } from "antd";

import Error404Image from "common/assets/icons/404.png";
import NoAccessImage from "common/assets/icons/no-access.png";
import useAuth from "common/hooks/useAuth";
import { Link } from "react-router-dom";

interface FallbackRouteProps {
	adminPath?: boolean;
}

function FallbackRoute(props: FallbackRouteProps) {
	const { auth } = useAuth();
	return (
		<div className="fallback" style={{ paddingTop: 80 }}>
			<Empty
				image={props.adminPath ? NoAccessImage : Error404Image}
				description={
					!props.adminPath || "Page needs admin access. Page locked."
				}
				style={{
					fontSize: 20,
				}}
			/>
			<div
				className="controls"
				style={{ textAlign: "center", marginTop: 30, fontWeight: 500 }}
			>
				<Link to="/">{auth.isLogged ? "Dashboard" : "Login"}</Link>
			</div>
		</div>
	);
}

export default FallbackRoute;
